import { parsePhoneNumberFromString } from 'libphonenumber-js';

const formatPhoneNumber = (number) => {
    const phoneNumber = parsePhoneNumberFromString(number, 'US'); 
    if (!phoneNumber) return number;

    const digits = phoneNumber.nationalNumber;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;

};


export default formatPhoneNumber;