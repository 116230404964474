import { useDispatch, useSelector } from "react-redux"
import { setDialogues } from "./slice/app"

const useStore = () => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state);

    const setDialogue = (data) => dispatch(setDialogues(data))

    return { store, setDialogue }
}

export default useStore