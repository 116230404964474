// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import { ReactComponent as NapworksLogo } from "assets/images/small-logos/logo-napworks.svg";

function Footer({ company, links }) {
  // const { href, name } = company;
  const { size } = typography;

  return (
    <SoftBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="start"
      alignItems="center"
      >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        flexWrap="wrap"
        color="#323232"
        fontSize={size.md}>
        {/* <SoftTypography textTransform="none" fontSize="14px">
          Powered By
          <SoftTypography fontWeight="medium" fontSize="16px"> NAP Works Pvt. Ltd.</SoftTypography>
        </SoftTypography> */}
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://napworks.in/", name: "Creative Tim" },
  links: [
    { href: "https://www.creative-tim.com/", name: "Creative Tim" },
    { href: "https://www.creative-tim.com/presentation", name: "About Us" },
    { href: "https://www.creative-tim.com/blog", name: "Blog" },
    { href: "https://www.creative-tim.com/license", name: "License" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
