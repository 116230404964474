// @mui material components
import Card from "@mui/material/Card";
import React, { useEffect, useMemo, useState } from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkIcon from "@mui/icons-material/Link";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import useStore from "store/hook";
import { InspectionSection } from "./components/InspectionSection";
import headerBg from "assets/images/curved-images/Header.png";
import SoftAvatar from "components/SoftAvatar";
import api from "store/api/api";
import formatStringWithSpaces from "../../services/stringconverter";
import getTimeFromMilliseconds from "../../services/timeconverter";
import truncateString from "services/truncatestring";
import DeleteInspection from "components/SoftDialog/DeleteInspection";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CircularLoader from "components/SoftDialog/Loader";
import checkboxgreen from "assets/images/logos/checkboxgreen.png";
import checkboxwhite from "assets/images/logos/checkboxwhite.png";
import { useRef } from "react";
import html2pdf from "html2pdf.js";
import getDateOrTime from "../../services/getDateOrTime";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";

import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import brand from "assets/images/logos/image.png";
import signature from "assets/images/signature2.png";
import convertMillisecondsToDate from "services/dateconverter";
import formatPhoneNumber from "services/formatPhoneNo";
import ChangeStatusOfInspection from "components/SoftDialog/ChangeStatusOfInspection";
import ChangeStatusBackOfInspection from "components/SoftDialog/ChangeStatusBackOfInspection";
import ChangeStatusOfInspectionAdmin from "components/SoftDialog/ChangeStatusOfInspectionAdmin";
import { toast } from "react-toastify";

const weatherData = [
  { label: "Weather Condition" },
  { label: "Temperature" },
  { label: "Deficiencies Found" },
  { label: "Status" },
];

const InspectionData = [
  { label: "Report Number" },
  { label: "Temperature" },
  { label: "Deficiencies Found" },
];

function InspectionOverview() {
  const { setDialogue, store } = useStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [inspectionData, setInspectionData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [links, setLinks] = useState([]);
  const [manager, setManager] = useState([]);
  const [contacts, setContact] = useState([]);
  const [discipline, setDiscipline] = useState([]);
  const [referenceData, setReferenceData] = useState({});
  const [pdfloading, setPdfLoading] = useState(false);
  const [pfdData, setPdfData] = useState([]);
  const [pdfdata2, setpdfdata2] = useState([]);
  const [imagesDiscipline, setImagesDiscipline] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [managerSignature, setManagerSignature] = useState("");
  const [inspectorSignature, setInspectorSignature] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState("");
  const { success } = store?.app?.dialogs?.isSuccess || {};

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/signin");
    } else {
      setCurrentUserRole(loginData.account_type);
      fetchInspectionDetails();
    }
  }, [navigate, success]);

  const fetchInspectionDetails = async () => {
    try {
      setIsLoading(true);

      const response = await api.getInspectionDetails({
        inspection_id: id,
      });
      setInspectionData(response.data);
      // console.log("responsedata");

      // if(response.data.length > 0){
      //   console.log(response.data.added_by.signature)
      setInspectorSignature(response.data.added_by?.signature);
      // }
      setProjectData(response?.data?.projectDetail);
      setLinks(response?.data?.projectDetail?.links);
      setManager(response?.data?.projectDetail?.manager);
      if (response?.data?.projectDetail?.manager) {
        setManagerSignature(
          response?.data?.projectDetail?.manager[0].signature
        );
      }
      setContact(response?.data?.projectDetail?.site_contacts);
      setDiscipline(response?.data?.discipline_data);
      if (response?.data?.discipline_data?.length > 0) {
        if (response?.data?.discipline_data[0].images?.length > 0) {
          setImagesDiscipline(response?.data?.discipline_data[0].images);
        }
      }
      // console.log(response?.data?.discipline_data[0].images)
      // console.log(response?.data?.discipline_data);
      setReferenceData(response?.data?.refferenceData);
      setPdfData([
        {
          name: "Project Name",
          email: response?.data?.projectDetail?.name || "",
          phone: "Project No.",
          value: `#${response?.data?.projectDetail?.project_number}` || "",
        },
        {
          name: "Location",
          email: response?.data?.projectDetail?.location || "",
          phone: "Project Inspection Form",
          value: "#20",
        },
      ]);

      setpdfdata2([
        {
          name: "Weather",
          email: response?.data?.weather_condition,
          phone: "Temperature",
          temp: response?.data?.temperature,
        },
        {
          name: "Date",
          email: getDateOrTime(
            convertMillisecondsToDate(response?.data?.createdTime),
            "date"
          ),
          phone: "Time",
          value: getDateOrTime(
            convertMillisecondsToDate(response?.data?.createdTime),
            "time"
          ),
        },
      ]);

      if (response?.status == 0) {
        toast.error(response?.message);
        setDeleted(true);
      }

      if (response?.status == 7) {
        setDeleted(true);
      }
    } catch (error) {
      toast.error("Internal Server Error");
      console.error("Error in fetching details", error);
    } finally {
      setIsLoading(false);
    }
  };

  const ImageComponent = ({ imageUrl }) => {
    const [imageSrc, setImageSrc] = useState(imageUrl);

    useEffect(() => {
      // const convertImage = async () => {
      //   if (imageUrl?.startsWith("https://firebasestorage.googleapis.com")) {
      //     const base64Image = await convertImgToBase64(imageUrl);
      //     setImageSrc(base64Image);
      //   }
      // };
      // convertImage();
      setImageSrc(imageUrl);
    }, [imageUrl]);

    return (
      <SoftBox component="img" src={imageSrc} alt="Soft UI Logo" width="100%" />
    );
  };

  const convertImgToBase64 = async (imgUrl) => {
    try {
      const response = await fetch(imgUrl, { mode: "cors" }); // Ensure CORS is enabled
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to Base64:", error);
      return imgUrl; // Fallback to original URL if Base64 conversion fails
    }
  };

  const replaceImagesWithBase64 = async () => {
    const images = document.querySelectorAll("img");
    for (let img of images) {
      if (img.src.startsWith("https://firebasestorage.googleapis.com")) {
        img.src = await convertImgToBase64(img.src);
      }
    }
  };

  const uploadImagesToServer = async (firebaseImageUrls) => {
    try {
      // console.log(firebaseImageUrls);
      const response = await fetch("https://api.cciportal.com/upload-images", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ imageUrls: firebaseImageUrls }), // Send the array directly
      });

      const data = await response.json();
      // console.log("New Image URLs:", data.newImageUrls);
      return data.newImageUrls;
    } catch (error) {
      console.error("Error uploading images:", error);
      return firebaseImageUrls; // Fallback to original URLs if upload fails
    }
  };

  const deleteImagesFromServer = async (deleteImageUrls) => {
    try {
      const response = await fetch("https://api.cciportal.com/delete-images", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ imagePaths: deleteImageUrls }), // Send the array directly
      });

      const data = await response.json();
      return data.status;
    } catch (error) {
      console.error("Error uploading images:", error);
      return deleteImageUrls; // Fallback to original URLs if upload fails
    }
  };

  const updateDOMWithNewImages = (newImageUrls) => {
    if (!contentRef.current) return;

    // Select only images inside elements with class 'pdf-image'
    const images = contentRef.current.querySelectorAll(".pdf-image");

    images.forEach((img, index) => {
      if (newImageUrls[index]) {
        img.src = newImageUrls[index]; // Update the src attribute
      }
    });
  };

  const updateDOMWithManagerSignature = (newImageUrls) => {
    if (!contentRef.current) return;

    // Select only images inside elements with class 'pdf-image'
    const images = contentRef.current.querySelectorAll(".manager-signature");

    images.forEach((img, index) => {
      if (newImageUrls[index]) {
        img.src = newImageUrls[index]; // Update the src attribute
      }
    });
  };

  const updateDOMWithInspectorSignature = (newImageUrls) => {
    if (!contentRef.current) return;

    // Select only images inside elements with class 'pdf-image'
    const images = contentRef.current.querySelectorAll(".inspector-signature");

    images.forEach((img, index) => {
      if (newImageUrls[index]) {
        img.src = newImageUrls[index]; // Update the src attribute
      }
    });
  };

  const contentRef = useRef(null);

  const generatePDF = async () => {
    if (!contentRef.current) return;
    let deletedImagesArray = [];
    setPdfLoading(true);
    if (imagesDiscipline.length > 0) {
      const newImageUrls = await uploadImagesToServer(imagesDiscipline);
      if (newImageUrls.length > 0) {
        updateDOMWithNewImages(newImageUrls);
        deletedImagesArray = [...deletedImagesArray, ...newImageUrls];
      }
    }
    if (managerSignature != "") {
      const uploadManagerSignature = await uploadImagesToServer([
        managerSignature,
      ]);
      if (uploadManagerSignature.length > 0) {
        updateDOMWithManagerSignature(uploadManagerSignature);
        deletedImagesArray = [...deletedImagesArray, ...uploadManagerSignature];
      }
    }
    if (inspectorSignature != "") {
      const uploadInspectorSignature = await uploadImagesToServer([
        inspectorSignature,
      ]);
      if (uploadInspectorSignature.length > 0) {
        updateDOMWithInspectorSignature(uploadInspectorSignature);
        deletedImagesArray = [
          ...deletedImagesArray,
          ...uploadInspectorSignature,
        ];
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    const element = contentRef.current;

    const options = {
      margin: [5, 5, 20, 5],
      filename: `${projectData?.name}   ${inspectionData?.inspection_number}.pdf`,
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { scale: window.devicePixelRatio * 3 || 3, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    await new Promise((resolve) => setTimeout(resolve, 1000));

    const pdf = await html2pdf().from(element).set(options).toPdf().get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const footerText = [
        "4795 S. Church St. Ext. Suite 2",
        "Roebuck, SC 29376",
        "(864) - 586-6111",
      ];

      footerText.forEach((line, index) => {
        pdf.text(line, pageWidth / 2, pageHeight - 15 + index * 5, {
          align: "center",
        });
      });

      pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 20, pageHeight - 10, {
        align: "right",
      });
    }

    pdf.save(options.filename);

    await deleteImagesFromServer(deletedImagesArray);
    setPdfLoading(false);
  };

  const downloadPDF = () => {
    generatePDF();
  };

  const handleClick = (inspectionData) => {
    if (inspectionData?.is_deleted == true) {
      toast.error(
        "This Inspeciton Is  deleted, you cannot Edit this Inspection"
      );
      return;
    } else {
      navigate(`/update-inspection/${inspectionData?._id}`);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteInspection type="inspection" />
      <ChangeStatusOfInspection />
      <ChangeStatusBackOfInspection />
      <ChangeStatusOfInspectionAdmin />
      <SoftBox py={1}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}
            >
              <SoftTypography color="white" variant="h6" fontSize="20px">
                <Link to="/inspections" style={{ color: "white" }}>
                  <SoftTypography
                    component="span"
                    fontWeight="light"
                    variant="h6"
                    fontSize="20px"
                  >
                    Inspections &gt;{" "}
                  </SoftTypography>
                </Link>
                Inspection Overview
              </SoftTypography>
            </SoftBox>

            {deleted == true ? (
              <SoftBox
                display="flex"
                flexDirection="column"
                height="calc(100vh - 180px)"
                alignItems="center"
                justifyContent="center"
                px={2}
              >
                <SoftTypography
                  fontSize="30px"
                  fontWeight="medium"
                  color="fade"
                  pb={2}
                >
                  This inspection is no longer available. Admins only can view
                  details.
                </SoftTypography>
                <EmptyFolderIcon style={{ opacity: 0.8 }} />
              </SoftBox>
            ) : isLoading ? (
              <SoftBox
                display="flex"
                height="calc(100vh - 180px)"
                justifyContent="center"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="subtitle1" color="textSecondary">
                  <CircularLoader type="large" />
                </SoftTypography>
              </SoftBox>
            ) : (
              <SoftBox>
                <SoftBox
                  px={{ xs: 2, md: 3 }}
                  py={2}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                  justifyContent="space-between"
                >
                  <SoftBox display="flex" flexDirection="column" gap={1} pr={5}>
                    <SoftTypography
                      color="info"
                      fontWeight="bold"
                      fontSize="36px"
                      textTransform="capitalize"
                    >
                      {projectData?.name} - {projectData?.project_number}
                    </SoftTypography>

                    <SoftBox
                      sx={{
                        backgroundColor: "#F1F4F8",
                        borderRadius: "8px",
                        padding: "8px 12px",
                        marginBottom: "4px",
                        width: "100%",
                      }}
                    >
                      <SoftBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="8px"
                      >
                        <SoftBox
                          display="flex"
                          alignItems="center"
                          gap={1}
                          sx={{ minWidth: 450 }}
                        >
                          <SoftTypography
                            fontSize="16px"
                            color="lightblack"
                            textTransform="capitalize"
                            fontWeight="bold"
                          >
                            Inspection:
                          </SoftTypography>
                          <SoftTypography
                            fontSize="16px"
                            color="primary"
                            textTransform="capitalize"
                            fontWeight="medium"
                          >
                            {inspectionData?.inspection_number}
                          </SoftTypography>
                        </SoftBox>

                        <SoftTypography
                          fontSize="16px"
                          color="primary"
                          textTransform="capitalize"
                          fontWeight="medium"
                        >
                          {convertMillisecondsToDate(
                            inspectionData?.createdTime
                          )}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>

                    <SoftBox
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                      gap={12}
                    >
                      <SoftBox>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Project Manager
                        </SoftTypography>

                        <SoftTypography
                          color="lightBlack"
                          fontSize="16px"
                          display="flex"
                          gap={2}
                          textTransform="capitalize"
                          sx={{}}
                        >
                          {manager?.length > 0
                            ? manager?.map((manager, index) => (
                                <Link
                                  to={`/accounts/profile/${manager?._id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <SoftBox
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      gap: "8px",
                                      transition: "background-color 0.3s",
                                    }}
                                  >
                                    <SoftAvatar
                                      src={manager?.profile_picture || ""}
                                      alt="profile Image"
                                      size="lg"
                                      variant="rounded"
                                    />
                                    <SoftTypography
                                      className="inspector-name"
                                      variant="text"
                                      fontSize="14px"
                                      color="#212121"
                                      textTransform="capitalize"
                                    >
                                      {truncateString(manager?.full_name, 25)}
                                    </SoftTypography>
                                  </SoftBox>
                                </Link>
                              ))
                            : ""}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Location
                        </SoftTypography>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="14px"
                          textTransform="capitalize"
                          mt={2}
                        >
                          {projectData?.location}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox
                    display="flex"
                    py={1.5}
                    flexDirection="column"
                    gap={2}
                  >
                    <SoftButton
                      variant="gradient"
                      color="primary"
                      sx={{
                        padding: "0px",
                        width: "200px",
                        fontSize: "12px",
                        borderRadius: "8px",
                      }}
                      onClick={() => handleClick(inspectionData)}
                    >
                      Edit Inspection
                    </SoftButton>
                    
                    {inspectionData?.status !== "ready_for_distribution" &&
                      currentUserRole == "admin" && (
                        <SoftButton
                          variant="gradient"
                          color="primary"
                          sx={{
                            padding: "0px",
                            width: "200px",
                            fontSize: "12px",
                            borderRadius: "8px",
                          }}
                          onClick={() =>
                            setDialogue({
                              type: "changeInspectionStatusAdmin",
                              isOpen: true,
                              inspectionId: id,
                              status: inspectionData?.status,
                            })
                          }
                        >
                          Approve Inspection
                        </SoftButton>
                      )}
                    {inspectionData?.status !== "archived" && (
                      <SoftButton
                        onClick={() =>
                          setDialogue({
                            type: "changeInspectionStatus",
                            isOpen: true,
                            inspectionId: id,
                            status: inspectionData?.status,
                          })
                        }
                        variant="gradient"
                        color="info"
                        sx={{
                          padding: "0px",
                          width: "200px",
                          fontSize: "12px",
                          position: "relative",
                          borderRadius: "8px",
                        }}
                      >
                        {inspectionData?.status === "new_report"
                          ? "Send to project Manager"
                          : inspectionData?.status === "under_manager_review"
                          ? "Final Review"
                          : inspectionData?.status ===
                            "under_editor_final_review"
                          ? "Ready For Distribution"
                          : inspectionData?.status === "ready_for_distribution"
                          ? "Archived"
                          : ""}

                        {/* {formatStringWithSpaces(inspectionData?.status)} */}
                      </SoftButton>
                    )}

                    {(inspectionData?.status === "ready_for_distribution" ||
                      inspectionData?.status === "archived") && (
                      <SoftButton
                        variant="gradient"
                        color="download"
                        sx={{
                          padding: "0px",
                          width: "200px",
                          fontSize: "12px",
                          borderRadius: "8px",
                          backgroundColor: "#FF4646",
                        }}
                        onClick={downloadPDF}
                      >
                        {pdfloading ? (
                          <CircularLoader type="small" />
                        ) : (
                          "Download Report"
                        )}
                      </SoftButton>
                    )}

                    {inspectionData?.status !== "new_report" && (
                      <SoftButton
                        onClick={() =>
                          setDialogue({
                            type: "changeInspectionBackStatus",
                            isOpen: true,
                            inspectionId: id,
                            status: inspectionData?.status,
                          })
                        }
                        variant="gradient"
                        color="info"
                        sx={{
                          padding: "3px",
                          width: "200px",
                          fontSize: "12px",
                          background: "#E8A317",
                          position: "relative",
                          borderRadius: "8px",
                        }}
                      >
                        {inspectionData?.status === "new_report"
                          ? "Send to project Manager"
                          : inspectionData?.status === "under_manager_review"
                          ? "Change Status to New Report"
                          : inspectionData?.status ===
                            "under_editor_final_review"
                          ? "Change Status to Manager Review"
                          : inspectionData?.status === "ready_for_distribution"
                          ? "Change Status to Final Review"
                          : inspectionData?.status === "archived"
                          ? "Change Status to Ready for Distributionn"
                          : ""}

                        {/* {formatStringWithSpaces(inspectionData?.status)} */}
                      </SoftButton>
                    )}

                    <SoftButton
                      onClick={() =>
                        setDialogue({
                          type: "deleteInspection",
                          isOpen: true,
                          inspectionId: id,
                          is_deleted: true,
                        })
                      }
                      variant="gradient"
                      color="error"
                      sx={{
                        padding: "0px",
                        width: { xs: "200px" },
                        fontSize: "12px",
                        borderRadius: "8px",
                        backgroundColor: "#FF4646",
                      }}
                    >
                      Remove Inspection
                    </SoftButton>
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                  gap={1}
                  px={3}
                  pb={4}
                >
                  {projectData?.internal_notes && (
                    <SoftBox
                      border="0.5px solid #000000"
                      borderRadius="6px"
                      padding="10px"
                      maxHeight="130px"
                      minHeight="130px"
                      sx={{
                        width: {
                          xs: "100%",
                          sm: 200,
                          md: 300,
                          lg: 550,
                          xl: 550,
                        },
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        "&::-webkit-scrollbar": { display: "none" },
                      }}
                    >
                      <SoftTypography
                        color="lightBlack"
                        fontSize="16px"
                        fontWeight="bold"
                      >
                        Internal Notes
                      </SoftTypography>
                      <SoftTypography
                        color="lightBlack"
                        fontSize="10px"
                        fontWeight="medium"
                        paddingTop="5px"
                      >
                        {projectData?.internal_notes}
                      </SoftTypography>
                    </SoftBox>
                  )}

                  <SoftBox
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    {links?.length > 0 && (
                      <SoftBox
                        width="250px"
                        sx={{ px: { xs: 0, md: 2, lg: 2, xl: 2 } }}
                      >
                        <SoftTypography
                          color="lightBlack"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Project Links
                        </SoftTypography>
                        {links?.map((link, index) => (
                          <SoftTypography
                            key={index}
                            color="primary"
                            fontSize="14px"
                            display="inline-flex" // ✅ Ensures text wraps next to the icon
                            flexWrap="wrap"
                            alignItems="center"
                            justifyContent="start"
                            sx={{
                              wordBreak: "break-word",
                              overflowWrap: "break-word",
                              maxWidth: "100%",
                            }}
                          >
                            <Link
                              to={link}
                              target="_blank"
                              sx={{ textDecoration: "none", color: "inherit" }}
                            >
                              <SoftBox
                                display="flex"
                                justifyContent="center"
                                sx={{ color: "primary.main" }}
                              >
                                <LinkIcon
                                  sx={{
                                    color: "primary.main",
                                    marginRight: "8px",
                                    width: 16,
                                    height: 16,
                                    transform: "rotate(-40deg)",
                                    flexShrink: 0,
                                  }}
                                />
                                {link}
                              </SoftBox>
                            </Link>
                          </SoftTypography>
                        ))}
                      </SoftBox>
                    )}

                    {contacts?.length > 0 && (
                      <SoftBox width="350px">
                        <SoftTypography
                          color="#212121"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Contacts
                        </SoftTypography>
                        {contacts?.map((contact, index) => (
                          <SoftBox
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                              backgroundColor: "#F1F4F8",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginBottom: "4px",
                              overflowX: "auto",
                              overflowY: "hidden",
                              whiteSpace: "nowrap",
                              scrollbarWidth: "none",
                              "&::-webkit-scrollbar": { display: "none" },
                            }}
                          >
                            <SoftBox
                              display="flex"
                              flexDirection="column"
                              color="primary"
                            >
                              <SoftBox
                                display="flex"
                                alignItems="center"
                                justifyContent="start"
                                gap="8px"
                              >
                                <SoftTypography
                                  fontSize="14px"
                                  color="primary"
                                  textTransform="uppercase"
                                  fontWeight="medium"
                                >
                                  {contact?.name}
                                </SoftTypography>

                                <SoftTypography
                                  fontSize="12px"
                                  color="primary"
                                  textTransform="capitalize"
                                  fontWeight="medium"
                                >
                                  {contact?.companyName}
                                </SoftTypography>
                              </SoftBox>

                              <SoftBox display="flex" gap="8px">
                                {contact?.email && (
                                  <a
                                    href={`mailto:${contact?.email}`}
                                    sx={{
                                      textDecoration: "none",
                                      color: "inherit",
                                    }}
                                  >
                                    <SoftBox
                                      display="flex"
                                      gap="8px"
                                      color="primary"
                                    >
                                      <EmailIcon color="primary" />
                                      <SoftTypography
                                        fontSize="14px"
                                        color="primary"
                                      >
                                        {contact?.email}
                                      </SoftTypography>
                                    </SoftBox>
                                  </a>
                                )}

                                {contact?.phoneNo && (
                                  <SoftBox
                                    display="flex"
                                    gap="8px"
                                    color="primary"
                                  >
                                    <PhoneIcon color="primary" />
                                    <SoftTypography
                                      fontSize="14px"
                                      color="primary"
                                    >
                                      {contact?.country_code}{" "}
                                      {formatPhoneNumber(contact?.phoneNo)}
                                    </SoftTypography>
                                  </SoftBox>
                                )}
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ))}
                      </SoftBox>
                    )}
                  </SoftBox>
                </SoftBox>

                <SoftBox px={3}>
                  <SoftTypography
                    textAlign="center"
                    backgroundColor="primary.main"
                    color="white"
                    fontSize="20px"
                    fontWeight="medium"
                    padding="2px 15px"
                    borderRadius="6px"
                  >
                    Inspection Report
                  </SoftTypography>
                  <SoftBox display="flex" justifyContent="space-between" py={3}>
                    {weatherData?.map(
                      (item, index) =>
                        inspectionData && (
                          <SoftBox key={index}>
                            <SoftTypography
                              color="lightBlack"
                              fontSize="16px"
                              fontWeight="bold"
                            >
                              {item?.label}
                            </SoftTypography>

                            {item.label === "Status" ? (
                              <SoftTypography
                                color="primary"
                                fontSize="16px"
                                fontWeight="bold"
                                mt={0.5}
                              >
                                {inspectionData?.status ==
                                "under_manager_review"
                                  ? "Under Review"
                                  : inspectionData?.status ==
                                    "under_editor_final_review"
                                  ? "Final Review"
                                  : formatStringWithSpaces(
                                      inspectionData?.status
                                    )}
                              </SoftTypography>
                            ) : item.label === "Weather Condition" ? (
                              <SoftTypography
                                color="lightblack"
                                fontSize="16px"
                                fontWeight="regular"
                                textTransform="capitalize"
                                mt={0.5}
                              >
                                {inspectionData?.weather_condition}
                              </SoftTypography>
                            ) : item.label === "Temperature" ? (
                              <SoftTypography
                                color="lightblack"
                                fontSize="16px"
                                fontWeight="regular"
                                display="flex"
                                mt={0.5}
                              >
                                {inspectionData?.temperature}
                                <SoftTypography fontWeight="dark">
                                  <PanoramaFishEyeIcon
                                    sx={{
                                      marginLeft: "1px",
                                      marginBottom: "14px",
                                      width: 7,
                                      height: 7,
                                      filter: "brightness(0) contrast(9)",
                                    }}
                                  />
                                </SoftTypography>
                                F
                              </SoftTypography>
                            ) : item.label === "Deficiencies Found" ? (
                              <SoftTypography
                                color="lightblack"
                                fontSize="16px"
                                fontWeight="regular"
                                textTransform="uppercase"
                                mt={0.5}
                              >
                                {formatStringWithSpaces(
                                  inspectionData?.deficiencies_found.join(", ")
                                )}
                              </SoftTypography>
                            ) : null}
                          </SoftBox>
                        )
                    )}
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  px={3}
                  pb={5}
                  pt={1}
                >
                  <SoftTypography display="flex" gap={2}>
                    <SoftTypography
                      textAlign="center"
                      color="primary"
                      fontSize="20px"
                      fontWeight="bold"
                      padding="2px 0px"
                      borderRadius="6px"
                    >
                      Time Arrived
                    </SoftTypography>

                    <SoftTypography
                      textAlign="center"
                      backgroundColor="primary.main"
                      color="white"
                      fontSize="16px"
                      fontWeight="medium"
                      padding="5px 15px"
                      borderRadius="6px"
                    >
                      {getTimeFromMilliseconds(inspectionData?.arrival_time)}
                    </SoftTypography>
                  </SoftTypography>

                  <SoftBox display="flex" gap={2}>
                    <SoftTypography
                      textAlign="center"
                      backgroundColor="primary.main"
                      color="white"
                      fontSize="16px"
                      fontWeight="medium"
                      padding="5px 15px"
                      borderRadius="6px"
                    >
                      {getTimeFromMilliseconds(inspectionData?.time_left)}
                    </SoftTypography>

                    <SoftTypography
                      textAlign="center"
                      color="primary"
                      fontSize="20px"
                      fontWeight="bold"
                      padding="2px 0px"
                      borderRadius="6px"
                    >
                      Time Left
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>

                {referenceData && Object?.keys(referenceData)?.length !== 0 && (
                  <SoftBox px={3}>
                    <SoftBox borderRadius="6px" backgroundColor="shadow.main">
                      <SoftTypography
                        textAlign="center"
                        backgroundColor="primary.main"
                        color="white"
                        fontSize="20px"
                        fontWeight="medium"
                        padding="2px 15px"
                        borderRadius="6px"
                      >
                        Re - Inspection Information
                      </SoftTypography>

                      <SoftBox
                        display="flex"
                        px={3}
                        justifyContent="space-between"
                        py={2}
                      >
                        {InspectionData?.map((item, index) => (
                          <SoftBox
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                          >
                            <SoftTypography
                              color="lightBlack"
                              fontSize="16px"
                              fontWeight="bold"
                            >
                              {item?.label}
                            </SoftTypography>

                            {item?.label === "Report Number" ? (
                              <SoftTypography
                                color="lightblack"
                                fontSize="16px"
                                fontWeight="regular"
                              >
                                {referenceData?.inspection_number}
                              </SoftTypography>
                            ) : item.label === "Temperature" ? (
                              <SoftTypography
                                color="lightblack"
                                fontSize="16px"
                                fontWeight="regular"
                                display="flex"
                              >
                                {referenceData?.temperature}
                                <SoftTypography fontWeight="dark">
                                  <PanoramaFishEyeIcon
                                    sx={{
                                      marginLeft: "1px",
                                      marginBottom: "14px",
                                      width: 7,
                                      height: 7,
                                      filter: "brightness(0) contrast(9)",
                                    }}
                                  />
                                </SoftTypography>
                                F
                              </SoftTypography>
                            ) : (
                              <SoftTypography
                                color="lightblack"
                                fontSize="16px"
                                fontWeight="regular"
                              >
                                {formatStringWithSpaces(
                                  referenceData?.deficiencies_found.join(", ")
                                )}
                              </SoftTypography>
                            )}
                          </SoftBox>
                        ))}

                        <Link
                          to={`/inspection-overview/${referenceData?._id}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <ChevronRightIcon
                            sx={{
                              width: 28,
                              height: 28,
                            }}
                          />
                        </Link>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                )}

                {discipline && (
                  <SoftBox
                    px={3}
                    py={4}
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    {discipline?.map((item, index) => (
                      <InspectionSection
                        key={index}
                        title={item?.disciplineName}
                        subtitle={item?.location}
                        comments={item?.comment}
                        notes={item?.notes}
                        inspectionType={item?.inspectionType}
                        images={item?.images}
                      />
                    ))}
                  </SoftBox>
                )}
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>

      <SoftBox sx={{ display: "none" }}>
        <Container ref={contentRef} maxWidth="md">
          {/* Header */}
          <SoftBox display="flex" alignItems="center">
            <SoftBox width="100%" textAlign="center">
              {brand && (
                <SoftBox
                  component="img"
                  src={brand}
                  alt="Soft UI Logo"
                  width="9rem"
                />
              )}
              <SoftTypography
                color="#000000"
                fontSize="8px"
                fontWeight="bold"
                sx={{ lineHeight: "15px" }}
                mt={-2}
              >
                {" "}
                Helping Ensure a Safer Built Environment{" "}
              </SoftTypography>
            </SoftBox>
          </SoftBox>

          <SoftBox py={1} pt={1}>
            <Table sx={{ border: "1px solid #000000" }}>
              <TableBody>
                {pfdData?.map((contact, index) => (
                  <TableRow sx={{ border: "1px solid #000000" }}>
                    <TableCell
                      align="left"
                      sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                    >
                      <SoftTypography
                        sx={{ color: "#000000" }}
                        fontSize="12px"
                        fontWeight="normal"
                      >
                        {contact.name}{" "}
                      </SoftTypography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                    >
                      <SoftTypography
                        sx={{ color: "#000000" }}
                        fontSize="12px"
                        fontWeight="medium"
                      >
                        {contact?.email}{" "}
                      </SoftTypography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                    >
                      <SoftTypography
                        sx={{ color: "#000000" }}
                        fontSize="12px"
                        fontWeight="normal"
                      >
                        {contact?.phone}{" "}
                      </SoftTypography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                    >
                      {contact.value && (
                        <SoftTypography
                          sx={{ color: "#000000" }}
                          fontSize="12px"
                          fontWeight="bold"
                        >
                          {contact.value}{" "}
                        </SoftTypography>
                      )}
                      {contact?.temp && (
                        <SoftTypography
                          sx={{ color: "#000000" }}
                          fontSize="12px"
                          fontWeight="bold"
                          display="flex"
                          position="relative"
                        >
                          {contact?.temp}
                          <SoftTypography
                            sx={{ color: "#000000" }}
                            fontSize="12px"
                            fontWeight="bold"
                            display="flex"
                            position="relative"
                          >
                            °F
                          </SoftTypography>
                        </SoftTypography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SoftBox>

          <SoftBox py={1} pt={1}>
            <Table sx={{ border: "1px solid #000000" }}>
              <TableBody>
                {pdfdata2?.map((contact, index) => (
                  <TableRow sx={{ border: "1px solid #000000" }}>
                    <TableCell
                      align="left"
                      sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                    >
                      <SoftTypography
                        sx={{ color: "#000000" }}
                        fontSize="12px"
                        fontWeight="normal"
                      >
                        {contact.name}{" "}
                      </SoftTypography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                    >
                      <SoftTypography
                        sx={{ color: "#000000" }}
                        fontSize="12px"
                        fontWeight="medium"
                      >
                        {contact?.email}{" "}
                      </SoftTypography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                    >
                      <SoftTypography
                        sx={{ color: "#000000" }}
                        fontSize="12px"
                        fontWeight="normal"
                      >
                        {contact?.phone}{" "}
                      </SoftTypography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                    >
                      {contact.value && (
                        <SoftTypography
                          sx={{ color: "#000000" }}
                          fontSize="12px"
                          fontWeight="bold"
                        >
                          {contact.value}{" "}
                        </SoftTypography>
                      )}
                      {contact?.temp && (
                        <SoftTypography
                          sx={{ color: "#000000" }}
                          fontSize="12px"
                          fontWeight="bold"
                          display="flex"
                          position="relative"
                        >
                          {contact?.temp}
                          <SoftTypography
                            sx={{ color: "#000000" }}
                            fontSize="12px"
                            fontWeight="bold"
                            display="flex"
                            position="relative"
                          >
                            °F
                          </SoftTypography>
                        </SoftTypography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                <TableCell
                  align="left"
                  sx={{ border: "1px solid #000000", width: "25%" }} // Set consistent width
                >
                  <SoftTypography
                    sx={{ color: "#000000" }}
                    fontSize="12px"
                    fontWeight="normal"
                  >
                    Deficiencies Found:
                  </SoftTypography>
                </TableCell>

                <TableCell
                  colSpan={3}
                  sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    border: "1px solid #000000",
                  }}
                >
                  <SoftBox
                    display="flex"
                    alignItems="start"
                    justifyContent="start"
                    gap={6}
                  >
                    <SoftBox
                      display="flex"
                      gap="2px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SoftBox position="relative" mt={0.5} mr={0.5}>
                        {inspectionData?.deficiencies_found.includes("no") ? (
                          <SoftBox
                            component="img"
                            src={checkboxgreen}
                            sx={{
                              width: 15,
                              height: 15,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <SoftBox
                            component="img"
                            src={checkboxwhite}
                            sx={{
                              width: 15,
                              height: 15,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </SoftBox>

                      <SoftTypography
                        color="lightBlack"
                        fontSize="14px"
                        fontWeight="medium"
                        display="flex"
                        sx={{ color: "#212121" }}
                      >
                        No
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      gap="2px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <SoftBox position="relative" mt={0.5} mr={0.5}>
                        {inspectionData?.deficiencies_found.includes("yes") ? (
                          <SoftBox
                            component="img"
                            src={checkboxgreen}
                            sx={{
                              width: 15,
                              height: 15,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <SoftBox
                            component="img"
                            src={checkboxwhite}
                            sx={{
                              width: 15,
                              height: 15,
                              objectFit: "cover",
                              borderRadius: "2px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </SoftBox>

                      <SoftTypography
                        color="lightBlack"
                        fontSize="14px"
                        fontWeight="medium"
                        display="flex"
                        sx={{ color: "#212121" }}
                      >
                        Yes
                        <SoftTypography
                          color="lightBlack"
                          fontSize="14px"
                          fontWeight="regular"
                          display="flex"
                          sx={{ color: "#212121" }}
                        >
                          (see comments below)
                        </SoftTypography>
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </TableCell>
              </TableBody>
            </Table>
          </SoftBox>

          <SoftBox py={1}>
            <SoftTypography variant="h6" size="14px" fontWeight="bold" py={1}>
              Site Contact
            </SoftTypography>
            <Table
              sx={{
                border: "1px solid #000000",
                tableLayout: "fixed",
                width: "100%",
              }}
              my={2}
            >
              {/* <TableRow
                sx={{
                  border: "1px solid #000000",
                  backgroundColor: " #D9D9D9",
                }}
              >
                <TableCell align="left" sx={{ border: "1px solid #000000" }}>
                  {" "}
                  <SoftTypography
                    sx={{ color: "#000000" }}
                    fontSize="12px"
                    fontWeight="bold"
                  >
                    Name
                  </SoftTypography>
                </TableCell>
                <TableCell align="left" sx={{ border: "1px solid #000000" }}>
                  {" "}
                  <SoftTypography
                    sx={{ color: "#000000" }}
                    fontSize="12px"
                    fontWeight="bold"
                  >
                    Company Name
                  </SoftTypography>
                </TableCell>

                <TableCell align="left" sx={{ border: "1px solid #000000" }}>
                  {" "}
                  <SoftTypography
                    sx={{ color: "#000000" }}
                    fontSize="12px"
                    fontWeight="bold"
                  >
                    Email
                  </SoftTypography>
                </TableCell>
                <TableCell align="left" sx={{ border: "1px solid #000000" }}>
                  {" "}
                  <SoftTypography
                    sx={{ color: "#000000" }}
                    fontSize="12px"
                    fontWeight="bold"
                  >
                    Phone Number
                  </SoftTypography>
                </TableCell>
              </TableRow> */}
              <TableBody>
                {contacts?.length > 0 ? (
                  contacts?.map((contact, index) => (
                    <TableRow sx={{ border: "1px solid #000000" }}>
                      <TableCell
                        align="left"
                        sx={{
                          border: "1px solid #000000",
                          backgroundColor: "#D9D9D9",
                        }}
                      >
                        <SoftTypography
                          sx={{ color: "#000000" }}
                          fontSize="12px"
                          fontWeight="normal"
                        >
                          Name
                        </SoftTypography>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ border: "1px solid #000000" }}
                      >
                        <SoftTypography
                          sx={{ color: "#000000" }}
                          fontSize="12px"
                          fontWeight="normal"
                        >
                          {contact.name}
                        </SoftTypography>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          border: "1px solid #000000",
                          backgroundColor: "#D9D9D9",
                        }}
                      >
                        <SoftTypography
                          sx={{ color: "#000000" }}
                          fontSize="12px"
                          fontWeight="normal"
                        >
                          Company Name
                        </SoftTypography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid #000000" }}
                      >
                        <SoftTypography
                          sx={{ color: "#000000" }}
                          fontSize="12px"
                          fontWeight="normal"
                        >
                          {contact?.companyName}
                        </SoftTypography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={{ border: "1px solid #000000" }}>
                    <TableCell
                      colSpan={4}
                      align="center"
                      sx={{ border: "1px solid #000000" }}
                    >
                      <SoftTypography
                        sx={{ color: "#000000" }}
                        fontSize="12px"
                        fontWeight="normal"
                      >
                        No Contacts
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </SoftBox>

          <SoftBox>
            <SoftBox
              display="flex"
              alignItems="start"
              justifyContent="start"
              gap={6}
            >
              <softBox
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SoftTypography
                  fontSize="14px"
                  fontWeight="medium"
                  color="#212121"
                  mt={0.4}
                >
                  Re-Inspection Required
                </SoftTypography>
              </softBox>

              <SoftBox
                display="flex"
                gap="2px"
                alignItems="center"
                justifyContent="center"
              >
                <SoftBox position="relative" mr={0.5}>
                  {inspectionData?.deficiencies_found.includes(
                    "reinspection_required"
                  ) ? (
                    <SoftBox
                      component="img"
                      src={checkboxgreen}
                      sx={{
                        width: 15,
                        height: 15,
                        objectFit: "cover",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <SoftBox
                      component="img"
                      src={checkboxwhite}
                      sx={{
                        width: 15,
                        height: 15,
                        objectFit: "cover",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </SoftBox>

                <SoftTypography
                  color="lightBlack"
                  fontSize="14px"
                  fontWeight="medium"
                  display="flex"
                  sx={{ color: "#212121" }}
                >
                  Yes
                </SoftTypography>
              </SoftBox>

              <SoftBox
                display="flex"
                gap="2px"
                alignItems="center"
                justifyContent="center"
              >
                <SoftBox position="relative" mr={0.5}>
                  {!inspectionData?.deficiencies_found.includes(
                    "reinspection_required"
                  ) ? (
                    <SoftBox
                      component="img"
                      src={checkboxgreen}
                      sx={{
                        width: 15,
                        height: 15,
                        objectFit: "cover",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <SoftBox
                      component="img"
                      src={checkboxwhite}
                      sx={{
                        width: 15,
                        height: 15,
                        objectFit: "cover",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </SoftBox>

                <SoftTypography
                  color="lightBlack"
                  fontSize="14px"
                  fontWeight="medium"
                  display="flex"
                  sx={{ color: "#212121" }}
                >
                  No
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox py={1}>
              <SoftTypography variant="h6" size="14px" fontWeight="bold">
                Discipline : {discipline[0]?.disciplineName}
              </SoftTypography>

              {discipline?.length > 0 && (
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  py={1}
                  borderBottom="1px solid #000000"
                >
                  {/* {discipline[0]?.disciplineName && (
                    <SoftBox>
                      <SoftTypography
                        fontSize="14px"
                        fontWeight="bold"
                        color="#212121"
                      >
                        {discipline[0]?.disciplineName}
                      </SoftTypography>
                    </SoftBox>
                  )} */}

                  {discipline[0]?.inspectionType && (
                    <SoftBox>
                      <SoftTypography
                        fontSize="12px"
                        fontWeight="regular"
                        color="#212121"
                      >
                        <strong fontWeight="medium"> Inspection Type : </strong>{" "}
                        {discipline[0]?.inspectionType}
                      </SoftTypography>
                    </SoftBox>
                  )}

                  {discipline[0]?.location && (
                    <SoftBox>
                      <SoftTypography
                        fontSize="12px"
                        fontWeight="regular"
                        color="#212121"
                      >
                        <strong fontWeight="medium"> Location : </strong>
                        {discipline[0]?.location}
                      </SoftTypography>
                    </SoftBox>
                  )}

                  {discipline[0]?.comment && (
                    <SoftBox>
                      <SoftTypography
                        fontSize="12px"
                        fontWeight="regular"
                        color="#212121"
                      >
                        <strong fontWeight="medium"> Comments : </strong>
                        {discipline[0]?.comment}
                      </SoftTypography>
                    </SoftBox>
                  )}

                  {discipline[0]?.notes && (
                    <SoftBox>
                      <SoftTypography
                        fontSize="12px"
                        fontWeight="regular"
                        color="#212121"
                      >
                        <strong fontWeight="medium">Notes : </strong>
                        {discipline[0]?.notes}
                      </SoftTypography>
                    </SoftBox>
                  )}
                </SoftBox>
              )}
            </SoftBox>
          </SoftBox>

          <softBox sx={{ breakInside: "avoid", pageBreakInside: "avoid" }}>
            <SoftBox width="100%" display="flex" gap={8} py={2} mt={4}>
              <SoftBox
                width="100%"
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <SoftBox width="100%" textAlign="center">
                  {manager?.length > 0 && (
                    <SoftBox
                      fontSize="12px"
                      fontWeight="medium"
                      sx={{ color: "#000000" }}
                      borderBottom="1px solid #000000"
                    >
                      {manager[0]?.full_name}
                    </SoftBox>
                  )}

                  <SoftBox
                    fontSize="8px"
                    fontWeight="medium"
                    sx={{ color: "#000000" }}
                    py={0.5}
                  >
                    (Reviewed By - Printed Name)
                  </SoftBox>
                </SoftBox>

                <SoftBox width="100%" textAlign="center">
                  <SoftBox
                    fontSize="12px"
                    fontWeight="medium"
                    sx={{ color: "#000000" }}
                    borderBottom="1px solid #000000"
                  >
                    {managerSignature && (
                      <SoftBox
                        component="img"
                        height="35px"
                        className="manager-signature"
                        src={managerSignature}
                        alt="Soft UI Logo"
                        width="9rem"
                      />
                    )}
                  </SoftBox>
                  <SoftBox
                    fontSize="8px"
                    fontWeight="medium"
                    sx={{ color: "#000000" }}
                    py={0.5}
                  >
                    (Reviewed By - Signature)
                  </SoftBox>
                </SoftBox>
              </SoftBox>
              <SoftBox
                width="100%"
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <SoftBox width="100%" textAlign="center">
                  <SoftBox
                    fontSize="12px"
                    fontWeight="medium"
                    sx={{ color: "#000000" }}
                    borderBottom="1px solid #000000"
                  >
                    {inspectionData?.added_by?.full_name}
                  </SoftBox>
                  <SoftBox
                    fontSize="8px"
                    fontWeight="medium"
                    sx={{ color: "#000000" }}
                    py={0.5}
                  >
                    (Inspected By - Printed Name)
                  </SoftBox>
                </SoftBox>

                <SoftBox width="100%" textAlign="center">
                  <SoftBox
                    fontSize="12px"
                    fontWeight="medium"
                    sx={{ color: "#000000" }}
                    borderBottom="1px solid #000000"
                  >
                    {inspectorSignature && (
                      <SoftBox
                        component="img"
                        className="inspector-signature"
                        height="35px"
                        src={inspectorSignature}
                        alt="Soft UI Logo"
                        width="9rem"
                        // crossorigin="anonymous"
                      />
                    )}
                  </SoftBox>
                  <SoftBox
                    fontSize="8px"
                    fontWeight="medium"
                    sx={{ color: "#000000" }}
                    py={0.5}
                  >
                    (Inspected By - Signature)
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              {/* <SoftTypography
                color="#000000"
                fontSize="10px"
                fontWeight="bold"
                width="20%"
                sx={{ lineHeight: "15px" }}
              >
                4795 S. Church St. Ext. Suite 2 Roebuck, SC 29376 864-586-6111
              </SoftTypography> */}
            </SoftBox>

            {imagesDiscipline.length > 0 &&
              imagesDiscipline.map((image, index) => (
                // <ImageComponent key={index} imageUrl={image} />
                <SoftBox
                  key={index}
                  className="pdf-image"
                  component="img"
                  src={image}
                  alt="Soft UI Logo"
                  width="100%"
                />
              ))}
          </softBox>
        </Container>
      </SoftBox>
    </DashboardLayout>
  );
}

export default InspectionOverview;
