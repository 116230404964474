import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCD0o8iAnskLO5yO-vbQIUpddbRr5Xg118",
  authDomain: "cc-and-i-reporting.firebaseapp.com",
  projectId: "cc-and-i-reporting",
  storageBucket: "cc-and-i-reporting.firebasestorage.app",
  messagingSenderId: "747595997531",
  appId: "1:747595997531:web:bd211c235e973c46d5ba7e",
  measurementId: "G-WSFEHT8F0M",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const uploadImage = async (image) => {



  try {
    const timestamp = Date.now();
    const imageName = `${timestamp}_${image.name}`;
    const storageRef = firebase.storage().ref().child("profile_image");
    const imageRef = storageRef.child(imageName);
    await imageRef.put(image);
    const imageUrl = await imageRef.getDownloadURL();
    return imageUrl;
  } catch (error) {
    console.error("Error uploading image:", error);
    return "";
  }
};

export const uploadImages = async (images, setDiscipline) => {
  try {
    const imageUrls = [];

    for (const image of images) {
      const timestamp = Date.now();
      const imageName = `${timestamp}_${image.name}`;
      const storageRef = firebase
        .storage()
        .ref()
        .child(`profile_image/${imageName}`);

      const uploadTask = storageRef.put(image);

      const imageUrl = await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => reject(error),
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          }
        );
      });

      imageUrls.push(imageUrl);
    }

    setDiscipline((prev) => [
      {
        ...prev[0],
        images: [...prev[0].images, ...imageUrls],
      }
    ]);
    return imageUrls;
  } catch (error) {
    console.error("Error uploading images:", error);
    return [];
  }
};
