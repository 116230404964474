import PropTypes from 'prop-types';
import { store } from './index'
import { Provider } from 'react-redux'

function StoreProvider({ children }) {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
};

StoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default StoreProvider