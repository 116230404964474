import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dialogs: {
    apiResponse: {
      isOpen: false,
      message: "",
    },
    disableUser: {
      isOpen: false,
      userId: "",
      is_active: true,
    },
    deleteUser: {
      isOpen: false,
      userId: "",
      is_deleted: true,
      action: "",
    },
    deleteProject: {
      isOpen: false,
      projectId: "",
      is_deleted: true,
      action: "",
    },
    deleteInspection: {
      isOpen: false,
      inspectionId: "",
      is_deleted: true,
      action: "",
    },
    logoutUser: {
      isOpen: false,
      message: "",
    },
    removeInspector: {
      isOpen: false,
      userId: "",
      is_deleted: true,
      action: "",
    },
    inspectorSignature: {
      isOpen: false,
      userName: "",
      signatureUrl: "",
      userImage: "",
    },
    selectInspector: {
      isOpen: false,
      selectedInspectors: [],
    },

    selectDicipline: {
      isOpen: false,
      selectedDicipline: "",
    },
    addDicipline: {
      isOpen: false,
      isaddDicipline: false,
    },
    addLocation: {
      isOpen: false,
      disciplineId: "",
      isaddLocation: false,
    },
    addComment: {
      isOpencomment: false,
      addComment: "",
      predefinedcomments: [],
      isAddcomment: false,
    },
    addAppdata: {
      isOpen: false,
      disciplineId: "",
      action: "",
      text: "",
      inspectionTypeId: "",
      commentId: "",
      isEdit: false,
      success: false,
    },
    expandTable: {
      isOpen: false,
      disciplineId: "",
    },
    deleteAppdata: {
      isOpen: false,
      disciplinId: "",
      locationId: "",
      commentId: "",
      is_deleted: true,
      action: "",
    },
    selectInspection: {
      isOpen: false,
      inspectionId: "",
    },
    markAsComplete: {
      isOpen: false,
      projectId: "",
      isCompleted: true,
    },
    udateDicipline: {
      isOpen: false,
    },
    reOpenProject: {
      isOpen: false,
      projectId: "",
      isCompleted: false,
    },
    changeInspectionStatus: {
      isOpen: false,
      inspectionId: "",
      status: "",
    },
    openTimePicker: {
      isOpen: false,
      action: "",
    },
    openDateTimePicker: {
      isOpen: false,
      action: "",
    },
    isSuccess: {
      success: false,
    },
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDialogues: (state, { payload }) => {
      state.dialogs[payload.type] = payload;
    },

    addInspector: (state, { payload }) => {
      state.dialogs.selectInspector.selectedInspectors = [
        ...state.dialogs.selectInspector.selectedInspectors,
        payload,
      ];

      state.dialogs.selectInspector.isOpen = false;
    },

    removeInspector: (state, { payload }) => {
      state.dialogs.selectInspector.selectedInspectors =
        state.dialogs.selectInspector.selectedInspectors.filter(
          (inspector) => inspector.id !== payload.id
        );
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDialogues, setAuth, logOut, addInspector, removeInspector } =
  appSlice.actions;

export default appSlice.reducer;
