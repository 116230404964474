import React from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import formatStringWithSpaces from "services/stringconverter";
import SoftAvatar from "components/SoftAvatar";
import convertMillisecondsToDate from "services/dateconverter";

function Author({ name, picture }) {
  const data = localStorage?.getItem("loginData");
  const adminData = JSON.parse(data);
  return (
    <SoftBox display="flex" alignItems="center" pt="8px">
      <SoftBox mr={2}>
        <SoftAvatar
          src={
            picture || adminData?.profile_picture ||
            "https://i.pinimg.com/736x/07/33/ba/0733ba760b29378474dea0fdbcb97107.jpg"
          }
          alt="image"
          size="lg"
          variant="rounded"
        />
      </SoftBox>

      <SoftBox display="flex" flexDirection="column" gap={0.8}>
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="lightBlack"
          fontSize="16px"
          textTransform="none"
        >
         {name || adminData?.fullName}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

export const columns = [
  { name: "Discipline Name", align: "left" },
  { name: "Type", align: "left" },
  { name: "Added By", align: "left" },
  { name: "Created At", align: "left" },
  { name: "Action", align: "left" },
];

function Rows(data, setDialogue, setExpandDesciPlineId, expandDisciplineId) {


  return {
    inspectionTypes: data?.inspectionTypes,
    comments: data?.comments,
    disciplineId: data?.discipline_id,


    "Discipline Name": (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        textTransform="capitalize"
        fontSize="16px"
        padding="0px 0px 0px 14px !important"
        sx={{
          display: "block",
          whiteSpace: "normal",
          wordWrap: "break-word",
          lineHeight: "1.5em",
        }}
      >
        {data?.name}
      </SoftTypography>
    ),
    Type: (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        fontSize="16px"
        textTransform="capitalize"
      >
        {formatStringWithSpaces(data?.type)}
      </SoftTypography>
    ),

    "Created At": (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="regular"
        fontSize="16px"
        textTransform="capitalize"
        display="inline-flex"
        alignItems="center"
      >
        {convertMillisecondsToDate(data?.createdTime)}
      </SoftTypography>
    ),

    "Added By": (
      <Author
        name={data?.added_by_name}
        picture={data?.added_by_profile_picture}
      />
    ),
    Action: (
      <SoftBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        ml={1.5}
        gap={3}
        onClick={(e) => e.stopPropagation()}
      >
        <DeleteIcon
          sx={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            color: "error.main",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDialogue({
              type: "deleteAppdata",
              isOpen: true,
              disciplinId: data?.discipline_id,
              locationId: "",
              commentId: "",
              is_deleted: true,
              action: "discipline",
            });
          }}
        />

        <EditIcon
          sx={{
            cursor: "pointer",
            width: "20px",
            height: "20px",
            color: "white.main",
            backgroundColor: "primary.main",
            padding: "2px",
            borderRadius: "50%",
            marginTop: "2px",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDialogue({
              type: "addAppdata",
              isOpen: true,
              disciplineId: data?.discipline_id,
              locationId: "",
              commentId: "",
              is_deleted: true,
              action: "discipline",
              text: data?.name,
              isEdit: true,
            });
          }}
        />

        <ExpandMoreIcon
          sx={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            color: "#000000",
            fontWeight: "bold",
            transition: "transform 0.3s ease-in-out",
            transform:
              expandDisciplineId == data?.discipline_id
                ? "rotate(180deg)"
                : "rotate(0deg)",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setExpandDesciPlineId((prev) =>
              prev === data?.discipline_id ? "" : data?.discipline_id
            );
          }}
        />
      </SoftBox>
    ),
  };
}

export default Rows;
