// @mui material components
import Card from "@mui/material/Card";
import React, { useMemo, useEffect, useState } from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkIcon from "@mui/icons-material/Link";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams } from "react-router-dom";
import useStore from "store/hook";
import DeleteProject from "components/SoftDialog/DeleteProject";
import Table from "examples/Tables/Table";
import UserRow, { columns } from "store/action/ProjectOverview";
import headerBg from "assets/images/curved-images/Header.png";
import { Link } from "react-router-dom";
import api from "store/api/api";
import { useNavigate } from "react-router-dom";
import MarkComplete from "components/SoftDialog/MarkComplete";
import { ReactComponent as TickIcon } from "assets/images/small-logos/logo-tick.svg";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";
import SoftAvatar from "components/SoftAvatar";
import truncateString from "../../services/truncatestring";
import DeleteInspection from "components/SoftDialog/DeleteInspection";
import SoftPagination from "components/SoftPagination";
import CircularLoader from "components/SoftDialog/Loader";
import formatPhoneNumber from "services/formatPhoneNo";

const contactData = [
  {
    name: "JOHN DOE",
    company: "napworks",
    email: "",
    phone: "+1 (123) 456-7890",
  },
  {
    name: "JOHN DOE",
    company: "napworks",
    email: "JohnDoe@napworks.in",
    phone: "+91 9503813834",
  },
  {
    name: "JOHN DOE",
    company: "napworks",
    email: "JohnDoe@napworks.in",
    phone: "+91 9503813834",
  },
];

function ProjectOverview() {
  const navigate = useNavigate();
  const { setDialogue, store } = useStore();
  const { id } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [inspectionData, setInspectionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const projectId = store?.app?.dialogs?.markAsComplete?.projectId || "";
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = useState(null);

  const { inspectionId } = store?.app?.dialogs?.deleteInspection || {};

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/sign-in");
    } else {
      setIsLoading(true);
      fetchProjectDetail();
      fetchInspectionList();
      setIsLoading(false);
    }
  }, [navigate, projectId, page, inspectionId]);

  const fetchProjectDetail = async () => {
    try {
      setIsLoading(true);
      const response = await api.getProjectDetail({
        project_id: id,
      });
      setProjectData(response.data.project);
    } catch (error) {
      console.error("Error fetching project detail:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInspectionList = async () => {
    try {
      setIsLoading(true);
      const response = await api.getInspectionListByProject({
        project_id: id,
        page: page,
      });
      setInspectionData(response?.data?.inspections);
      setPagination(response?.data?.pagination);
    } catch (error) {
      console.error("Error fetching project Inspections:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const rows = useMemo(
    () =>
      inspectionData?.length
        ? inspectionData.map((inspection) => UserRow(inspection, setDialogue))
        : [],
    [inspectionData, setDialogue]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteProject />
      <DeleteInspection type="projectoverview" />
      <MarkComplete
        status={
          projectData?.status === "completed" ? "Completed" : "Incomplete"
        }
      />
      <SoftBox py={1}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}
            >
              <SoftTypography color="white" variant="h6" fontSize="20px">
                <Link to="/projects" style={{ color: "white" }}>
                  <SoftTypography
                    component="span"
                    fontWeight="light"
                    variant="h6"
                    fontSize="20px"
                  >
                    Projects &gt;{" "}
                  </SoftTypography>
                </Link>
                Project Overview
              </SoftTypography>
            </SoftBox>

            {isLoading ? (
              <SoftBox
                display="flex"
                height="calc(100vh - 180px)"
                justifyContent="center"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="subtitle1" color="textSecondary">
                  <CircularLoader type="large" />
                </SoftTypography>
              </SoftBox>
            ) : (
              <SoftBox
                sx={{ height: "calc(100vh - 190px)", overflowY: "auto" }}
              >
                <SoftBox
                  px={{ xs: 2, md: 3 }}
                  py={3}
                  display="flex"
                  alignItems="start"
                  justifyContent="space-between"
                >
                  <SoftBox display="flex" flexDirection="column" gap={1.5}>
                    <SoftTypography
                      color="info"
                      fontWeight="bold"
                      fontSize="36px"
                      textTransform="capitalize"
                    >
                      {projectData?.name} - {projectData?.project_number}
                    </SoftTypography>

                    <SoftBox display="flex" gap={12}>
                      <SoftBox>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Project Manager
                        </SoftTypography>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="16px"
                          display="flex"
                          gap={2}
                          textTransform="capitalize"
                          sx={{}}
                        >
                          {projectData?.manager?.map((manager, index) => (
                            <Link
                              to={`/accounts/profile/${manager?._id}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              <SoftBox
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "start",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s",
                                }}
                              >
                                <SoftAvatar
                                  src={manager.profile_picture || ""}
                                  alt={truncateString(manager.full_name, 5)}
                                  size="lg"
                                  variant="rounded"
                                />
                                <SoftTypography
                                  className="inspector-name"
                                  variant="text"
                                  fontSize="14px"
                                  color="#212121"
                                  textTransform="capitalize"
                                >
                                  {truncateString(manager.full_name, 25)}
                                </SoftTypography>
                              </SoftBox>
                            </Link>
                          ))}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="20px"
                          fontWeight="bold"
                        >
                          Location
                        </SoftTypography>
                        <SoftTypography
                          color="lightBlack"
                          fontSize="16px"
                          textTransform="capitalize"
                        >
                          {projectData?.location}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox display="flex" flexDirection="column" gap={2}>
                    <SoftButton
                      variant="gradient"
                      color="primary"
                      sx={{
                        padding: "0px",
                        width: "190px",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                      onClick={() => navigate(`/projects/edit/${id}`)}
                    >
                      Edit Project
                    </SoftButton>

                    <SoftButton
                      onClick={() => {
                        setDialogue({
                          type: "markAsComplete",
                          isOpen: true,
                          projectId: id,
                          isCompleted: true,
                        });
                      }}
                      variant="gradient"
                      color="info"
                      sx={{
                        padding: "0px",
                        width: "100%",
                        fontSize: "14px",
                        position: "relative",
                        borderRadius: "8px",
                      }}
                    >
                      <TickIcon
                        style={{
                          position: "absolute",
                          left: "5px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          height: "16px",
                        }}
                      />
                      {projectData?.status === "completed"
                        ? "Re-Open Project"
                        : "Mark as complete"}
                    </SoftButton>

                    <SoftButton
                      onClick={() =>
                        setDialogue({
                          type: "deleteProject",
                          isOpen: true,
                          projectId: id,
                          is_deleted: true,
                        })
                      }
                      variant="gradient"
                      color="error"
                      sx={{
                        padding: "0px",
                        width: "190px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        backgroundColor: "#FF4646",
                      }}
                    >
                      Remove Project
                    </SoftButton>
                  </SoftBox>
                </SoftBox>

                <SoftBox display="flex" gap={1} px={3} pb={4}>
                 {projectData?.internal_notes && <SoftBox
                    border="0.5px solid #000000"
                    borderRadius="6px"
                    padding="10px"
                    width="550px"
                    maxHeight="130px"
                    minHeight="130px"

                    sx={{
                      overflowY: "scroll", 
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": { display: "none" }, 
                    }}
                  >
                    <SoftTypography
                      color="lightBlack"
                      fontSize="16px"
                      fontWeight="bold"
                    >
                      Internal Notes
                    </SoftTypography>
                    <SoftTypography
                      color="lightBlack"
                      fontSize="10px"
                      fontWeight="medium"
                      paddingTop="5px"
                    >
                      {projectData?.internal_notes}
                    </SoftTypography>
                  </SoftBox> }

                  <SoftBox display="flex">

                  { projectData?.links?.length > 0 &&   <SoftBox width="250px" px={2}>
                      <SoftTypography
                        color="lightBlack"
                        fontSize="20px"
                        fontWeight="bold"
                      >
                        Project Links
                      </SoftTypography>
                      {projectData?.links?.map((link, index) => (
                        <SoftTypography
                          key={index}
                          color="primary"
                          fontSize="14px"
                          display="inline-flex" // ✅ Ensures text wraps next to the icon
                          flexWrap="wrap"
                          alignItems="center"
                          justifyContent="start"
                          sx={{
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            maxWidth: "100%",
                          }}
                        >
                          <Link
                            to={link}
                            target="_blank"
                            sx={{ textDecoration: "none", color: "inherit" }}
                          >
                            <SoftBox
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ color: "primary.main" }}
                            >
                              <LinkIcon
                                sx={{
                                  color: "primary.main",
                                  marginRight: "8px",
                                  width: 16,
                                  height: 16,
                                  transform: "rotate(-40deg)",
                                  flexShrink: 0,
                                }}
                              />
                              {link}
                            </SoftBox>
                          </Link>
                        </SoftTypography>
                      ))}
                    </SoftBox> }

                  {projectData?.site_contacts?.length > 0 && <SoftBox width="350px">
                      <SoftTypography
                        color="#212121"
                        fontSize="20px"
                        fontWeight="bold"
                      >
                        Site Contacts
                      </SoftTypography>
                      {projectData?.site_contacts?.map((contact, index) => (
                        <SoftBox
                          key={index}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            backgroundColor: "#F1F4F8",
                            borderRadius: "8px",
                            padding: "5px 8px",
                            marginBottom: "4px",
                            overflowX: "auto",
                            overflowY: "hidden",
                            whiteSpace: "nowrap",
                            scrollbarWidth: "none",
                            "&::-webkit-scrollbar": { display: "none" },
                          }}
                        >
                          <SoftBox
                            display="flex"
                            flexDirection="column"
                            color="primary"
                          >
                            <SoftBox
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                              gap="8px"
                            >
                              <SoftTypography
                                fontSize="14px"
                                color="primary"
                                textTransform="uppercase"
                                fontWeight="medium"
                              >
                                {contact?.name}
                              </SoftTypography>

                              <SoftTypography
                                fontSize="12px"
                                color="primary"
                                textTransform="capitalize"
                                fontWeight="medium"
                              >
                                {contact?.companyName}
                              </SoftTypography>
                            </SoftBox>

                            <SoftBox display="flex" gap="8px">
                              {contact?.email && (
                                <a
                                  href={`mailto:${contact?.email}`}
                                  sx={{ textDecoration: "none", color: "inherit" }}
                                >
                                  <SoftBox
                                    display="flex"
                                    gap="8px"
                                    color="primary"
                                  >
                                    <EmailIcon color="primary" />
                                    <SoftTypography
                                      fontSize="14px"
                                      color="primary"
                                    >
                                      {contact?.email}
                                    </SoftTypography>
                                  </SoftBox>
                                </a>
                              )}

                              {contact?.phoneNo && (
                                <SoftBox
                                  display="flex"
                                  gap="8px"
                                  color="primary"
                                >
                                  <PhoneIcon color="primary" />
                                  <SoftTypography
                                    fontSize="14px"
                                    color="primary"
                                  >
                                    {contact?.country_code}{" "}
                                    {formatPhoneNumber(contact?.phoneNo)}
                                  </SoftTypography>
                                </SoftBox>
                              )}
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      ))}
                    </SoftBox> }
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  sx={{
                    paddingTop: "28px",
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({
                          borders: { borderWidth, borderColor },
                        }) => `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <SoftBox
                    sx={{
                      backgroundColor: "primary.main",
                      backgroundImage: `url(${headerBg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                    }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px={3}
                    py={2}
                  >
                    <SoftTypography
                      color="white"
                      variant="h6"
                      fontSize="20px"
                      fontWeight="light"
                    >
                      Total Inspections -{" "}
                      <SoftTypography
                        component="span"
                        variant="h6"
                        fontSize="20px"
                      >
                        {pagination?.totalInspections || 0}
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>

                  {rows.length > 0 ? (
                    <>
                      <Table columns={columns} rows={rows} />

                      <SoftBox
                        py={1}
                        sx={{
                          backgroundColor: "#ffffff",
                          bottom: 0,
                          zIndex: 2,
                        }}
                      >
                        <SoftPagination
                          Totalpages={pagination?.totalPages}
                          currentPage={page}
                          changePage={setPage}
                        />
                      </SoftBox>
                    </>
                  ) : (
                    <SoftBox
                      sx={{
                        // height: "calc(100vh - 530px)",
                        // overflowY: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                          backgroundColor="shadow.main"
                        >
                          {columns.map(({ name, align, width }, index) => (
                            <SoftBox
                              key={index}
                              width={width}
                              align={align}
                              fontWeight="bold"
                              fontSize="16px"
                              py="14px"
                              px="24px"
                            >
                              {name}
                            </SoftBox>
                          ))}
                        </SoftBox>
                        <SoftTypography
                          fontSize="60px"
                          fontWeight="light"
                          height="70px"
                          color="fade"
                        >
                          0
                        </SoftTypography>
                        <SoftTypography
                          fontSize="25px"
                          fontWeight="medium"
                          color="fade"
                          pb={1.5}
                        >
                          INSPECTIONS
                        </SoftTypography>
                        <EmptyFolderIcon
                          style={{
                            opacity: 0.8,
                          }}
                        />
                      </SoftBox>
                    </SoftBox>
                  )}
                </SoftBox>
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ProjectOverview;
