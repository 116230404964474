import React from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import convertMillisecondsToDate from "../../services/dateconverter";
import formatStringWithSpaces from "../../services/stringconverter";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

export const columns = [
  { name: "#", align: "center" },
  { name: "Inspector", align: "left"},
  { name: "Date & Time", align: "left" },
  { name: "Weather", align: "center" },
  { name: "Temperature", align: "center" },
  { name: "Deficiency found", align: "center" },
  { name: "Status", align: "left" },
  { name: "Action", align: "center" },
];

function Rows(data, setDialogue) {
  return {

    linkPath: `/inspection-overview/${data._id}`,
    noLinkCells: ["Action"],
    "Inspector": (
      <SoftTypography
        variant="text"
        color="black"
        fontWeight="medium"
        textTransform="none"
        padding="25px 0px !important"
        fontSize="16px"
        sx={{
          display: "block",
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "150px",
          lineHeight: "21px",
        }}>
        {data?.added_by?.full_name}
      </SoftTypography>
    ),

    "#": (
      <SoftTypography
        variant="text"
        color="primary"
        fontWeight="medium"
        textTransform="none"
        padding="25px 0px !important"
        sx={{
          display: "block",
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "150px",
          lineHeight: "21px",
        }}>
        {data?.inspection_number}
      </SoftTypography>
    ),


    "Date & Time": (
      <SoftBox display="flex" flexDirection="column" alignItems="center">
        <SoftTypography
          variant="text"
          color="primary"
          fontSize="14px"
          fontWeight="medium"
          textTransform="none">
          {convertMillisecondsToDate(data?.createdTime)}
        </SoftTypography>
      </SoftBox>
    ),
    Weather: (
      <SoftTypography
        variant="text"
        color="black"
        fontWeight="medium"
        fontSize="16px"
        textTransform="uppercase">
        {data?.weather_condition} 
      </SoftTypography>
    ),
    Temperature: (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        fontSize="16px"
        textTransform="uppercase"
        display="inline-flex"
        alignItems="center"
      >
        {`${data?.temperature}`}
        <SoftTypography fontWeight="dark">
          <PanoramaFishEyeIcon
            sx={{
              marginLeft: "-1px",
              marginBottom: "12px",
              width: 7,
              height: 7,
              filter: "brightness(0) contrast(9)",
            }}
          />
        </SoftTypography>
        F
      </SoftTypography>
    ),
    "Deficiency found": (
      <SoftTypography
        variant="text"
        color="black"
        fontWeight="regular"
        fontSize="16px"
        textTransform="uppercase"
        sx={{
          display: "block",
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "150px",
          lineHeight: "1.2em",
        }}>
        {formatStringWithSpaces(data.deficiencies_found.join(", "))}
      </SoftTypography>
    ),
    Status: (
      <SoftTypography
              variant="text"
              color="lightBlack"
              fontWeight="medium"
              textTransform="capitalize"
              padding="18px 0px !important"
              sx={{
                display: "block",
                whiteSpace: "normal",
                wordWrap: "break-word",
                maxWidth: "200px",
                lineHeight: "21px",
                color:
                  data?.status === "new_report"
                    ? "#006903"
                    : data?.status === "under_manager_review"
                    ? "#BC6809"
                    : data?.status === "under_editor_final_review"
                    ? "#105DB5"
                    : "lightBlack",
              }}
            >
              {data?.status == "under_manager_review"
                ? "Under Review"
                : data?.status == "under_editor_final_review"
                ? "Final Review"
                : formatStringWithSpaces(data?.status)}
            </SoftTypography>
    ),
    Action: (
      <SoftBox display="flex" alignItems="center" justifyContent="center" gap={3}>
        <DeleteIcon
          sx={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            color: "error.main",
          }}
         onClick = {(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDialogue({
              type: "deleteInspection",
              isOpen: true,
              inspectionId: data._id,
              is_deleted: true,
            })
         }}
        />
      </SoftBox>
    ),
  };
}

export default Rows;
