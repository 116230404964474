// @mui material components
import Card from "@mui/material/Card";
import SearchIcon from "@mui/icons-material/Search";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import SoftPagination from "components/SoftPagination";
import UserRow, { columns } from "store/action/Projects";
import React, { useState } from "react";
import DeleteProject from "components/SoftDialog/DeleteProject";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";
// Data
import SoftInput from "components/SoftInput";
import useStore from "store/hook";
import { useNavigate } from "react-router-dom";
import headerBg from "assets/images/curved-images/Header.png";
import api from "store/api/api";
import CircularLoader from "components/SoftDialog/Loader";


function Projects() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const { setDialogue, store } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = React.useState("");
  const [rows, setRows] = useState([]);
  const [projectData, setProjectData] = useState([]);

  const handleSearch = () => {
      getProjects("search", searchData);
  };

  const {
    projectId
  } = store?.app?.dialogs?.deleteProject || {}; 
  
  const {
    success
  } = store?.app?.dialogs?.isSuccess || {};  

  React.useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/sign-in");
    } else if (searchData === "") {
      getProjects();
    }
    else {
      getProjects("all", searchData);
    }
  }, [navigate, searchData, page, success]);

  const getProjects = async (type = "all", searchData) => {

    searchData?.length > 0 ? type="search": type="all";

    try {
      setIsLoading(true);
      const response = await api.getProjectsList({
        type,
        page,
        searchData: searchData,
      });


      if (response.status === 1) {

        setRows(
          response.data.projects.map((project) => UserRow(project, setDialogue))
        );
        setProjectData(response.data);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteProject />
      <SoftBox pt={1}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "sticky",
                top: 0,
                zIndex: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={3}
              py={2}>
              <SoftTypography
                color="white"
                variant="h6"
                fontSize="20px"
                fontWeight="light">
                Total Projects -{" "}
                <SoftTypography component="span" variant="h6" fontSize="20px">
                  {projectData.totalProjects || 0}
                </SoftTypography>
              </SoftTypography>
              <SoftBox display="flex" gap={2}>
                <SoftBox
                  onClick={() => navigate("/projects/add-project")}
                  sx={{
                    cursor: "pointer",
                    border: "2px solid white",
                    borderRadius: "6px",
                    padding: "0px 42px",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <SoftTypography variant="h6" color="white">
                    Add Projects
                  </SoftTypography>
                </SoftBox>
                <SoftBox sx={{ width: "280px", borderRadius: "6px" }}>
                  <SoftInput
                    value={searchData}
                    onChange={(e) => setSearchData(e.target.value)}
                    placeholder="Search..."
                    icon={{
                      component: (
                        <SearchIcon
                          onClick={searchData.trim() ? handleSearch : undefined}
                          sx={{
                            cursor: searchData.trim() ? "pointer" : "default",
                          }}
                        />
                      ),
                      direction: "left",
                    }}
                    sx={{
                      padding: "10px 12px 10px 4px",
                      "& .MuiInputBase-input": {
                        fontSize: "16px",
                        fontWeight: "regular",
                      },
                    }}
                  />
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                position: "relative",
                "& .MuiTableContainer-root": {
                  maxHeight: rows.length > 0 ? "calc(100vh - 245px)" : "auto",
                  overflow: rows.length > 0 ? "auto" : "visible",
                },
                "& .MuiTableHead-root": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}>
              {isLoading ? (
                <SoftBox
                  display="flex"
                  height="calc(100vh - 180px)"
                  justifyContent="center"
                  alignItems="center"
                  p={3}>
                  <SoftTypography variant="subtitle1" color="textSecondary">
                    <CircularLoader type="large"/>
                  </SoftTypography>
                </SoftBox>
              ) : rows.length > 0 ? (
                <Table columns={columns} rows={rows} />
              ) : (
                <SoftBox
                  sx={{
                    height: "calc(100vh - 180px)",
                    overflowY: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                  }}>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={8}
                    width="100%">
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      backgroundColor="shadow.main">
                      {columns.map(({ name, align, width }, index) => (
                        <SoftBox
                          key={index}
                          width={width}
                          align={align}
                          fontWeight="bold"
                          fontSize="18px"
                          py="14px"
                          px="24px">
                          {name}
                        </SoftBox>
                      ))}
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center">
                      <SoftTypography
                        fontSize="90px"
                        fontWeight="light"
                        height="110px"
                        color="fade">
                        0
                      </SoftTypography>
                      <SoftTypography
                        fontSize="40px"
                        fontWeight="medium"
                        color="fade"
                        pb={2}>
                        PROJECTS
                      </SoftTypography>
                      <EmptyFolderIcon style={{ opacity: 0.8 }} />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )}
            </SoftBox>
            {rows.length > 0 && !isLoading && (
              <SoftBox
                sx={{
                  backgroundColor: "#ffffff",
                  position: "sticky",
                  bottom: 0,
                  zIndex: 2,
                }}>
                <SoftPagination
                  Totalpages={projectData.totalPages}
                  currentPage={projectData.currentPage}
                  changePage={setPage}
                />
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Projects;
