// @mui material components
import Card from "@mui/material/Card";
import React, { use, useState } from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams } from "react-router-dom";
import useStore from "store/hook";
import RemoveInspector from "components/SoftDialog/RemoveInspector";
import Table from "examples/Tables/Table";
import UserRow, { columns } from "store/action/Projects";
import InspectionRow, { Inspectioncolumns } from "store/action/Reports";
import headerBg from "assets/images/curved-images/Header.png";
import { FaUser } from "react-icons/fa6";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import InspectorSignature from "components/SoftDialog/InspectureSignature";
import { Link } from "react-router-dom";
import api from "store/api/api";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";
import DeleteUser from "components/SoftDialog/DeleteUser";
import convertMillisecondsToDate from "services/dateconverter";
import formatStringWithSpaces from "services/stringconverter";
import DeleteProject from "components/SoftDialog/DeleteProject";
import DeleteInspection from "components/SoftDialog/DeleteInspection";
import SoftPagination from "components/SoftPagination";
import CircularLoader from "components/SoftDialog/Loader";

function InspectorOverview() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const { setDialogue, store } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [inspectiondataRow, setInspectionRow] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [inspectionData, setInspectionDate] = useState([]);
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    image: "",
    lastLogin: "",
    signature: "",
    userRole: "",
  });

  const {
    success
  } = store?.app?.dialogs?.isSuccess || {}; 

  React.useEffect(() => {
    fetchUserData();
  }, []);

  React.useEffect(() => {
    if (form?.userRole == "inspector") {
      fetchInspectionDataforInspector();
    } else if (form?.userRole == "project_manager") {
      fetchProjectDataforManager();
    } else {
      fetchProjectDataforManager();
      fetchInspectionDataforInspector();
    }
  }, [navigate, page, success]);

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const response = await api.getUserDetail({ userId: id });

      if (response.status) {
        const userData = response.data;
        setForm((prev) => ({
          ...prev,
          fullName: userData.full_name || "",
          email: userData.email || "",
          image: userData.profile_picture || null,
          lastLogin: userData.login_time,
          signature: userData.signature,
          userRole: userData.account_type,
        }));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjectDataforManager = async () => {
    setTableLoading(true);
    try {
      const response = await api.getProjectsbyManager({
        userId: id,
        page,
      });

      if (response.status === 1) {
        setRows(
          response.data.projects.map((project) => UserRow(project, setDialogue))
        );
        setProjectData(response.data);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setTableLoading(false);
    }
  };

  const fetchInspectionDataforInspector = async () => {
    try {
      setTableLoading(true);
      const response = await api.getInspectionList({
        page,
        inspector_id: id,
      });

      if (response.status === 1) {
        setInspectionDate(response.data.pagination);
        setInspectionRow(
          response.data.inspections.map((report) =>
            InspectionRow(report, setDialogue)
          )
        );
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    } finally {
      setTableLoading(false);
    }
  };

  const handleClick = () => {
    navigate(`/accounts/edit/${id}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <RemoveInspector />
      <DeleteInspection type="userinspection" />
      <DeleteUser type="userdetails" />
      <DeleteProject type="userprojects" />
      <InspectorSignature />
      <SoftBox py={1}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}
            >
              <SoftTypography color="white" variant="h6" fontSize="20px">
                <SoftTypography
                  component="span"
                  fontWeight="light"
                  variant="h6"
                  fontSize="20px"
                >
                  <Link to="/reports" style={{ color: "white" }}>
                    {formatStringWithSpaces(form.userRole)} &gt;{" "}
                  </Link>
                </SoftTypography>
                {formatStringWithSpaces(form.userRole)} Overview
              </SoftTypography>
              <SoftTypography
                color="white"
                variant="h6"
                fontSize="20px"
                fontWeight="light"
              >
                <SoftTypography
                  component="span"
                  fontWeight="medium"
                  variant="h6"
                  fontSize="20px"
                >
                  Last Login{" "}
                </SoftTypography>
                - {convertMillisecondsToDate(form?.lastLogin)}
              </SoftTypography>
            </SoftBox>

            {isLoading ? (
              <SoftBox
                display="flex"
                height="calc(100vh - 180px)"
                justifyContent="center"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="subtitle1" color="textSecondary">
                  <CircularLoader type="large" />
                </SoftTypography>
              </SoftBox>
            ) : (
              <SoftBox
                sx={{ height: "calc(100vh - 190px)", overflowY: "auto" }}
              >
                <SoftBox
                  px={{ xs: 2, md: 3 }}
                  py={2}
                  display="flex"
                  alignItems="center"
                  flexDirection={{ md: "row", xs: "column" }}
                  justifyContent="space-between"
                  gap={{ md: "", xs: 5 }}
                >
                  <SoftBox
                    display="flex"
                    flexDirection={{ md: "row", xs: "column" }}
                    gap={2}
                    alignItems="center"
                  >
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="8px"
                      backgroundColor="white.main"
                      border="0.5px solid #02255B"
                      sx={{
                        height: "190px",
                        width: "190px",
                        overflow: "hidden",
                      }}
                    >
                      {form?.image ? (
                        <SoftBox
                          component="img"
                          height="100%"
                          src={form?.image}
                          alt={form?.fullName}
                          width="100%"
                        />
                      ) : (
                        <FaUser size={120} color="#C1C1C1" />
                      )}
                    </SoftBox>

                    <SoftBox display="flex" flexDirection="column" gap={1.5}>
                      <SoftTypography
                        display="flex"
                        alignItems="center"
                        fontSize="20px"
                        color="lightBlack"
                        fontWeight="bold"
                        gap={0.3}
                        textTransform="capitalize"
                        mb={0.5}
                      >
                        <PersonIcon
                          sx={{
                            color: "#02255B",
                            width: "25px",
                            height: "25px",
                          }}
                        />{" "}
                        {form?.fullName}
                      </SoftTypography>
                      <SoftTypography
                        display="flex"
                        alignItems="center"
                        gap={1}
                        fontSize="16px"
                        color="text"
                      >
                        <EmailIcon
                          sx={{
                            color: " #02255B",
                            width: "20px",
                            height: "20px",
                          }}
                        />{" "}
                        {form?.email}
                      </SoftTypography>

                      {form?.signature && (
                        <SoftBox
                          display="flex"
                          gap={1}
                          mt={0.5}
                          alignItems="center"
                          backgroundColor="input.main"
                          borderRadius="8px"
                          border="0.5px dashed #02255B"
                          padding="5px"
                          onClick={() =>
                            setDialogue({
                              type: "inspectorSignature",
                              isOpen: true,
                              userName: form?.fullName,
                              signatureUrl: form?.signature,
                              userImage: form?.image,
                            })
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          <SoftBox
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="8px"
                            backgroundColor="white.main"
                            boxShadow="0 0 16px 2px rgba(0, 0, 0, 0.1)"
                            sx={{
                              height: "40px",
                              width: "50px",
                              overflow: "hidden",
                            }}
                          >
                            {/* <FaUser size={15} color="#C1C1C1" /> */}
                            <img
                              src={form?.signature}
                              alt="Inspector Signature"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                          </SoftBox>
                          <SoftTypography
                            fontSize="16px"
                            color="primary"
                            fontWeight="bold"
                          >
                            {formatStringWithSpaces(form.userRole)} Signature
                          </SoftTypography>
                        </SoftBox>
                      )}
                    </SoftBox>
                  </SoftBox>

                  <SoftBox display="flex" flexDirection="column" gap={2}>
                    <SoftButton
                      variant="gradient"
                      color="primary"
                      sx={{
                        padding: "0px",
                        width: "190px",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                      onClick={handleClick}
                    >
                      Edit {formatStringWithSpaces(form.userRole)}
                    </SoftButton>

                    <SoftButton
                      variant="gradient"
                      color="error"
                      sx={{
                        padding: "0px",
                        width: "190px",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                      onClick={() =>
                        setDialogue({
                          isOpen: true,
                          userId: id,
                          is_deleted: true,
                          type: "deleteUser",
                        })
                      }
                    >
                      Remove {formatStringWithSpaces(form.userRole)}
                    </SoftButton>

                    <SoftTypography
                      onClick={() =>
                        navigate(`/inspectors/password-change/${id}`)
                      }
                      sx={{
                        textAlign: "center",
                        color: "lightBlack.main",
                        fontSize: "14px",
                        fontWeight: "medium",
                        border: "2px solid #212121",
                        padding: "6px 9px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "white.main",
                        },
                      }}
                    >
                      Change Password
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>

                {form?.userRole == "project_manager" && (
                  <SoftTypography
                    mt={2}
                    px={3}
                    fontSize="24px"
                    fontWeight="bold"
                  >
                    Assigned Projects ({projectData?.totalProjects || 0})
                  </SoftTypography>
                )}

                {form?.userRole == "inspector" && (
                  <SoftTypography
                    mt={2}
                    px={3}
                    fontSize="24px"
                    fontWeight="bold"
                  >
                    Total Inspections ({inspectionData?.totalInspections || 0})
                  </SoftTypography>
                )}

                {(form?.userRole == "inspector" ||
                  form?.userRole == "project_manager") && (
                  <SoftBox
                    sx={{
                      paddingTop: "20px",
                      "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                          borderBottom: ({
                            borders: { borderWidth, borderColor },
                          }) => `${borderWidth[1]} solid ${borderColor}`,
                        },
                      },
                    }}
                  >
                    {form?.userRole == "project_manager" &&
                      (rows.length > 0 ? (
                        <>
                          <Table columns={columns} rows={rows} />
                          <SoftBox
                            py={1}
                            sx={{
                              backgroundColor: "#ffffff",
                              bottom: 0,
                              zIndex: 2,
                            }}
                          >
                            <SoftPagination
                              Totalpages={projectData?.totalPages}
                              currentPage={page}
                              changePage={setPage}
                            />
                          </SoftBox>
                        </>
                      ) : (
                        <SoftBox
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <SoftBox
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                          >
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                              backgroundColor="shadow.main"
                            >
                              {columns.map(({ name, align, width }, index) => (
                                <SoftBox
                                  key={index}
                                  width={width}
                                  align={align}
                                  fontWeight="bold"
                                  fontSize="16px"
                                  py="14px"
                                  px="24px"
                                >
                                  {name}
                                </SoftBox>
                              ))}
                            </SoftBox>
                            <SoftTypography
                              fontSize="60px"
                              fontWeight="light"
                              height="70px"
                              color="fade"
                            >
                              0
                            </SoftTypography>
                            <SoftTypography
                              fontSize="25px"
                              fontWeight="medium"
                              color="fade"
                              pb={1.5}
                            >
                              PROJECTS
                            </SoftTypography>
                            <EmptyFolderIcon
                              style={{
                                opacity: 0.8,
                              }}
                            />
                          </SoftBox>
                        </SoftBox>
                      ))}

                    {form?.userRole == "inspector" &&
                      (inspectiondataRow.length > 0 ? (
                        <>
                          <Table
                            columns={Inspectioncolumns}
                            rows={inspectiondataRow}
                          />
                          <SoftBox
                            py={1}
                            sx={{
                              backgroundColor: "#ffffff",
                              bottom: 0,
                              zIndex: 2,
                            }}
                          >
                            <SoftPagination
                              Totalpages={inspectionData?.totalPages}
                              currentPage={page}
                              changePage={setPage}
                            />
                          </SoftBox>
                        </>
                      ) : (
                        <SoftBox
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <SoftBox
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                          >
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                              backgroundColor="shadow.main"
                            >
                              {Inspectioncolumns.map(
                                ({ name, align, width }, index) => (
                                  <SoftBox
                                    key={index}
                                    width={width}
                                    align={align}
                                    fontWeight="bold"
                                    fontSize="16px"
                                    py="14px"
                                    px="24px"
                                  >
                                    {name}
                                  </SoftBox>
                                )
                              )}
                            </SoftBox>
                            <SoftTypography
                              fontSize="60px"
                              fontWeight="light"
                              height="70px"
                              color="fade"
                            >
                              0
                            </SoftTypography>
                            <SoftTypography
                              fontSize="25px"
                              fontWeight="medium"
                              color="fade"
                              pb={1.5}
                            >
                              Inspections
                            </SoftTypography>
                            <EmptyFolderIcon
                              style={{
                                opacity: 0.8,
                              }}
                            />
                          </SoftBox>
                        </SoftBox>
                      ))}
                  </SoftBox>
                )}
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default InspectorOverview;
