import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import api from "store/api/api";
import { DialogActions } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { toast } from "react-toastify";
import CircularLoader from "./Loader";

export default function AddLocation() {
  const { store, setDialogue } = useStore();
  const [loading, setLoading] = React.useState(false);

  const { isOpen = false, disciplineId } =
    store?.app?.dialogs?.addLocation || {};

  const [addLocationData, setAddLocationData] = React.useState({
    name: "",
    discipline_id: "",
    type: "custom",
  });

  React.useEffect(() => {
    setAddLocationData((prev) => ({
      ...prev,
      discipline_id: disciplineId,
    }));
  }, [disciplineId]);

  const handleClose = () =>
    setDialogue({
      type: "addLocation",
      isOpen: false,
      disciplineId: "",
      isaddLocation: false,
    });

  const AddLocation = async () => {
    setLoading(true);
    try {
      const response = await api.addInspectiontype(addLocationData);
      if (response.status === 1) {
        setAddLocationData((prev) => ({
          ...prev,
          name: "",
          discipline_id: "",
        }));
        handleClose();
      } else {
        toast.error(response?.message || "Error  In Add Discipline");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    finally{
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      aria-labelledby="select-inspector-dialog"
      sx={{
        "& .MuiDialog-paper": {
          margin: 2,
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent
        sx={{ backgroundColor: "white.main", padding: "18px 24px !important" }}
      >
        <SoftBox display="flex" flexDirection="column" gap={2}>
          <SoftBox
            mt={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
          >
            <SoftTypography
              fontSize="26px"
              fontWeight="bold"
              sx={{ color: "#212121" }}
            >
              ADD NEW INSPECTION TYPE
            </SoftTypography>

            <SoftTypography
              fontSize="14px"
              fontWeight="400"
              sx={{
                color: "#737373",
                maxWidth: "70%",
              }}
            >
              Don't worry if you can't find a Inspectin type. You can add a new one by
              filling out the form here.
            </SoftTypography>
          </SoftBox>

          <SoftInput
            value={addLocationData?.name}
            onChange={(e) =>
              setAddLocationData((prev) => ({
                ...prev,
                name: e?.target?.value,
              }))
            }
            name="dicipline"
            placeholder="Enter New Inspection type"
            sx={{
              padding: "14px 12px",
              "& .MuiInputBase-input::placeholder": {
                color: "#737373 !important",
                fontSize: "16px",
                opacity: 1,
              },
            }}
          />

          <DialogActions
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              gap: 3,
              padding: "0px",
            }}
          >
            <SoftButton
              variant="gradient"
              color="primary"
              sx={{
                width: "45%",
                padding: "14px 24px",
                fontSize: "16px",
              }}
              onClick={AddLocation}
            >
             {loading == true? <CircularLoader type="small"/> : "Add"} 
            </SoftButton>
            <SoftTypography
              fontSize="16px"
              fontWeight="bold"
              align="center"
              color="primary"
              sx={{ margin: "0px !important", cursor: "pointer" }}
              onClick={handleClose}
            >
              Cancel
            </SoftTypography>
          </DialogActions>
        </SoftBox>
      </DialogContent>
    </Dialog>
  );
}
