export const BASE_URL = process.env.REACT_APP_PROD_URL;
// export const BASE_URL = 'http://localhost:8080';
export const API_KEY = 'G6EAQa1Pim694FUY&SZ8C5nbQuKD3';

export const LOGIN = 'auth/login';
export const GET_PROJECTS = 'project/getProjectList';
export const GET_PROJECT_DETAIL = 'project/getProjectDetail';
export const GET_USERS = 'admin/getUsers';
export const GET_USER_DETAIL = 'admin/getUserDetail';
export const GET_REPORTS = 'project/getProjectList';
export const ADD_PROJECT = 'admin/addProject';
export const ADD_ACCOUNT = 'auth/addAccount';
export const SEND_FORGOT_OTP = 'auth/sendForgotOtp';
export const VERIFY_OTP = 'auth/verifyOtp';
export const CHANGE_FORGOT_PASSWORD = 'auth/changeForgotPassword';
export const CHANGE_PASSWORD = 'profile/changePassword';
export const UPDATE_USER_STATUS = 'admin/updateUserStatus';
export const UPDATE_USER_DETAIL = 'admin/editUserDetail';
export const EDIT_PROJECT_DETAIL = 'admin/editProjectDetail';
export const GET_INSPECTORS_LIST = 'admin/searchProjectManagerList';
export const PROJECT_STATUS = 'admin/deleteProject';
export const MARK_AS_COMPLETE = 'admin/completeProject'; 
export const REOPEN_PROJECT = 'admin/reOpenProject'; 
export const SEARCH_INSPECTOR_LIST = 'admin/searchInspectorList';
export const GET_INSPECTIONS = 'admin/getInspectionList';
export const GET_INSPECTION_DETAIL = 'admin/getInspectionDetail';
export const INSPECTION_STATUS = 'admin/deleteInspection';
export const GET_INSPECTION_BYPROJECT = 'admin/getInspectionByProject';
export const ADD_DICCIPLINE = 'project/addDiscipline';
export const GET_DISCIPLINEDATA = 'admin/getDisciplineData';
export const COMPLETE_INSPECTION = 'admin/approveInspectionStatus';
export const ADD_INSPECTIONTYPE = 'project/addDisciplineInspectionType';
export const ADD_COMMENT = 'project/addDisciplineComment';
export const CHANGE_INPECTION_STATUS = 'inspection/updateInspectionStatus';
export const UPDATE_INSPECTION = 'inspection/editInspection';
export const EDIT_DISCIPLINE = 'project/editDiscipline';
export const EDIT_INSPECTIONTYPE  = 'project/editInspectionType';
export const EDIT_COMMENT = 'project/editComment';
export const DELETE_INSPECTIONTYPE = 'project/deleteInpectionType';
export const DELETE_COMMENT = 'project/deleteComment'; 
export const DELTED_DISCIPLINE = 'project/deleteDiscipline';
export const GETPROJECT_BY_MANAGER = 'admin/getProjectByProjectManager'



