// @mui material components
import { styled } from "@mui/material/styles";

export default styled("div")(({ theme, ownerState }) => {
  const { palette, functions, borders } = theme;
  const { error, success, disabled } = ownerState;

  const { inputColors, grey, input } = palette;
  const { pxToRem } = functions;
  const { borderRadius, borderWidth } = borders;

  // border color value
  let borderColorValue = inputColors.borderColor.main;

  if (error) {
    borderColorValue = inputColors.error;
  } else if (success) {
    borderColorValue = inputColors.success;
  }

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: disabled ? grey[200] : input.main,
    border: "0.5px solid",
    borderRadius: borderRadius.md,
    borderColor: borderColorValue,
    overflow: "hidden",

    "& .MuiInputBase-input": {
      height: pxToRem(20),
    },
  };
});
