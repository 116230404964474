// @mui material components
import Card from "@mui/material/Card";
import React from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import LockIcon from "@mui/icons-material/Lock";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { toast } from "react-toastify";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams, useNavigate } from "react-router-dom";
import headerBg from "assets/images/curved-images/Header.png";
import { Link } from "react-router-dom";
import api from "store/api/api";

function ChangePassword() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [form, setForm] = React.useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleChange = (e) =>
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const validateForm = () => {
    const requiredFields = {
      "New Password": form.newPassword,
      "Confirm Password": form.confirmPassword,
    };

    const emptyField = Object.entries(requiredFields).find(
      ([_, value]) => !value
    );
    if (emptyField) {
      toast.error(`${emptyField[0]} is required`);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setIsSubmitting(true);
      if (form.newPassword === form.confirmPassword) {
        const data = {
          userId: id,
          password: form.confirmPassword,
        };

        await api.updateUserDetail(data)
        navigate(`/accounts/edit/${id}`);
      } else {
        toast.error("New password and confirm password do not match");
      }
    } catch (error) {
      console.error("Failed to change password:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={1}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}>
              <SoftTypography color="white" variant="h6" fontSize="20px">
                <SoftTypography
                  component="span"
                  fontWeight="light"
                  variant="h6"
                  fontSize="20px">
                  <Link to="/accounts" style={{ color: "white" }}>
                    Accounts &gt;{" "}
                  </Link>
                  <Link to={`/accounts/edit/${id}`} style={{ color: "white" }}>
                    Edit Account &gt;{" "}
                  </Link>
                </SoftTypography>
                Change Password
              </SoftTypography>
            </SoftBox>

            <SoftBox sx={{ height: "calc(100vh - 190px)", overflowY: "auto" }}>
              <SoftBox
                px={{ xs: 2, md: 3 }}
                pt={2}
                pb={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <SoftTypography mt={1} mb={1} fontWeight="bold" fontSize="30px">
                  CHANGE PASSWORD
                </SoftTypography>
                <SoftTypography
                  fontSize="16px"
                  color="black.light"
                  px={{ xs: 2, md: 16 }}
                  textAlign="center">
                  For your account security, please choose a strong password
                  that includes a mix of letters, numbers, and special
                  characters. Avoid using easily guessable information like your
                  name or birthdate.
                </SoftTypography>
              </SoftBox>

              <SoftBox
                sx={{ px: { xs: 2, md: 14 }, pb: { xs: 4, md: 8 }, pt: 4 }}>
                <SoftBox
                  backgroundColor="input.main"
                  p={{ xs: 1, md: 2 }}
                  borderRadius={12}>
                  <SoftBox
                    px={{ xs: 2, md: 5 }}
                    py={{ xs: 3, md: 3 }}
                    backgroundColor="input.main"
                    borderRadius={12}
                    sx={{ maxWidth: "60%" }}>
                    <SoftBox mb={2} sx={{ position: "relative" }}>
                      <SoftInput
                        value={form.newPassword}
                        name="newPassword"
                        onChange={handleChange}
                        type={showNewPassword ? "text" : "password"}
                        placeholder="New Password"
                        icon={{ component: <LockIcon />, direction: "left" }}
                        sx={{
                          padding: "16px 40px 16px 4px",
                          "&.Mui-focused": {
                            padding: "16px 40px 16px 4px",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "#737373 !important",
                            fontSize: "16px",
                            opacity: 1,
                          },
                          "& .MuiInputBase-input": {
                            fontSize: "16px",
                          },
                        }}
                      />
                      <SoftBox
                        onClick={toggleNewPasswordVisibility}
                        sx={{
                          position: "absolute",
                          right: "12px",
                          top: "55%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "text.main",
                        }}>
                        {showNewPassword ? (
                          <VscEyeClosed size={20} />
                        ) : (
                          <VscEye size={20} />
                        )}
                      </SoftBox>
                    </SoftBox>

                    <SoftBox mb={2} sx={{ position: "relative" }}>
                      <SoftInput
                        value={form.confirmPassword}
                        name="confirmPassword"
                        onChange={handleChange}
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        icon={{ component: <LockIcon />, direction: "left" }}
                        sx={{
                          padding: "16px 40px 16px 4px",
                          "&.Mui-focused": {
                            padding: "16px 40px 16px 4px",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "#737373 !important",
                            fontSize: "16px",
                            opacity: 1,
                          },
                          "& .MuiInputBase-input": {
                            fontSize: "16px",
                          },
                        }}
                      />
                      <SoftBox
                        onClick={toggleConfirmPasswordVisibility}
                        sx={{
                          position: "absolute",
                          right: "12px",
                          top: "55%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "text.main",
                        }}>
                        {showConfirmPassword ? (
                          <VscEyeClosed size={20} />
                        ) : (
                          <VscEye size={20} />
                        )}
                      </SoftBox>
                    </SoftBox>

                    <SoftButton
                      variant="gradient"
                      color="primary"
                      fullWidth
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      sx={{
                        padding: "14px 24px",
                        mt: 1,
                        width: "75%",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}>
                      {isSubmitting ? "Changing..." : "Change Password"}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ChangePassword;
