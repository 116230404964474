import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { IoMdWarning } from "react-icons/io";
import api from "store/api/api";

export default function MarkComplete({status}) {
  const { store, setDialogue } = useStore();


  const isOpen = store?.app?.dialogs?.markAsComplete?.isOpen;
  const projectId = store?.app?.dialogs?.markAsComplete?.projectId || "";

  const handleClose = () => setDialogue({ isOpen: false, projectId: "", type: "markAsComplete" });

  const handleCase = async () => {
    try {
      const data = {
        projectId: projectId,
      };

      status == "Completed" ? await api.reopenProject(data) : await api.markAsComplete(data)
      handleClose();
      
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 14,
            pb: 2,
            pt: 3,
            margin: 0,
            borderRadius: "12px",
          },
        }}>
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1.5 }}>
          <SoftTypography
            variant="h5"
            fontWeight="bold"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 16,
              alignItems: "center",
            }}>
            <IoMdWarning fontSize="56px" /> {"Warning"}
          </SoftTypography>
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <SoftTypography
              variant="h6"
              fontWeight="regular"
              textAlign="center"
              sx={{ color: "lightBlack.main" }}>
            {status === "Completed"? "Are you sure, you want to reopen this project?" : "Are you sure, you want to mark this project as complete ?"}  
            </SoftTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            padding: "0px",
          }}>
          <SoftButton
            variant="gradient"
            color="error"
            sx={{
              width: "100%",
              padding: "14px 24px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            onClick={handleCase}>
            Continue
          </SoftButton>
          <SoftTypography
            fontSize="16px"
            fontWeight="bold"
            align="center"
            color="primary"
            sx={{ margin: "0px !important", cursor: "pointer" }}
            onClick={handleClose}>
            Cancel
          </SoftTypography>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
