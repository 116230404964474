const  convertMillisecondsToDate = (seconds) => {
       
  const  milliseconds = new Date(seconds * 1000);

    const date = new Date(milliseconds);
  
    const options = {
      year: 'numeric',
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, 
    };
  
    return date.toLocaleString('en-US', options).replace(',', ''); 
  }


  export default convertMillisecondsToDate;