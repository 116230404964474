import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";

export const InspectionSection = ({
  title,
  subtitle,
  comments,
  notes,
  inspectionType,
  images,
}) => (
  <SoftBox
    padding="10px"
    display="flex"
    flexDirection="column"
    borderRadius="6px"
    gap={0.5}
    backgroundColor="shadow.main"
  >
    <SoftTypography fontSize="16px" fontWeight="regular">
      <SoftTypography component="span" fontSize="16px" fontWeight="bold">
        {title},{" "}
      </SoftTypography>
      {subtitle}
    </SoftTypography>

    {inspectionType && (
      <SoftBox display="flex" gap={0.5}>
        <SoftTypography fontSize="14px" fontWeight="regular" color="text">
          Inspection Type:
        </SoftTypography>
        <SoftTypography fontSize="14px" fontWeight="regular" color="lightBlack">
          {inspectionType}
        </SoftTypography>
      </SoftBox>
    )}

    {comments && (
      <SoftBox>
        <SoftTypography fontSize="14px" fontWeight="regular" color="text">
          Comments
        </SoftTypography>
        <SoftTypography fontSize="14px" fontWeight="regular" color="lightBlack">
          {comments}
        </SoftTypography>
      </SoftBox>
    )}

    {notes && (
      <SoftBox>
        <SoftTypography fontSize="14px" fontWeight="regular" color="text">
          Notes
        </SoftTypography>
        <SoftTypography fontSize="14px" fontWeight="regular" color="lightBlack">
          {notes}
        </SoftTypography>
      </SoftBox>
    )}

    {images.lenght !== 0 && (
      <SoftBox display="flex" alignItems="center" gap={1} flexWrap="wrap">
        {images.map((image, index) => (
          <Link to={image} target="_blank">
            <SoftBox position="relative" mt={1}>
              <SoftBox
                key={index}
                component="img"
                src={image}
                sx={{
                  width: 80,
                  height: 80,
                  objectFit: "cover",
                  borderRadius: "12px",
                  border: "0.63px solid #02255B",
                }}
                
              />
            </SoftBox>
          </Link>
        ))}
      </SoftBox>
    )}
  </SoftBox>
);
