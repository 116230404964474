import { useMemo } from "react";
import { Link } from "react-router-dom";
import React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

function Table({ columns, rows }) {
  const { lightBlack, shadow } = colors;
  const { size, fontWeightBold } = typography;

  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.75}
        pb={1.75}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.lg}
        fontWeight={fontWeightBold}
        color={lightBlack.main}
        opacity={1}
        backgroundColor={shadow.main}
        borderBottom="none !important"
        sx={{ borderRadius: "0 !important" }}>
        {name}
      </SoftBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;
    const isEvenRow = key % 2 === 0;
    const linkPath = row.linkPath;
    const noLinkCells = row.noLinkCells || [];

    const tableRow = columns.map(({ name, align }) => {
      let template;

      const shouldLink = !noLinkCells.includes(name);

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            sx={{
              backgroundColor: isEvenRow ? "white" : "#F1F4F8",
              border: "none",
              verticalAlign: "middle",
            }}>
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftAvatar
                  src={row[name][0]}
                  name={row[name][1]}
                  variant="rounded"
                  size="sm"
                />
              </SoftBox>
              <SoftTypography
                variant="button"
                fontWeight="medium"
                sx={{ width: "max-content" }}>
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            px={1}
            py={1.5}
            textAlign={align}
            sx={{
              backgroundColor: isEvenRow ? "white" : "#F1F4F8",
              verticalAlign: "middle",
            }}>
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}>
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return (
      <TableRow key={rowKey}>
        {linkPath ? (
          <>
            {tableRow.map((cell, index) => (
              <React.Fragment key={index}>
                {noLinkCells.includes(columns[index].name) ? (
                  cell
                ) : (
                  <Link
                    to={linkPath}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "contents",
                    }}>
                    {cell}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          tableRow
        )}
      </TableRow>
    );
  });

  return useMemo(
    () => (
      <TableContainer sx={{ borderRadius: 0 }}>
        <MuiTable sx={{ borderRadius: 0 }}>
          <SoftBox
            component="thead"
            sx={{
              borderRadius: 0,
              backgroundColor: "white",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}>
            <TableRow
              sx={{
                borderRadius: "0 !important",
                border: "none",
                "& > *": {
                  border: "none !important",
                },
              }}>
              {renderColumns}
            </TableRow>
          </SoftBox>
          <TableBody
            sx={{
              "& tr": {
                border: "none !important",
                "& > *": {
                  border: "none !important",
                },
              },
              "& td, & th": {
                border: "none !important",
              },
            }}
          >
            {renderRows}
          </TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
