// @mui material components
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import { Menu, MenuItem } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { MdFileUpload } from "react-icons/md";
import { FaUser } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import headerBg from "assets/images/curved-images/Header.png";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import api from "store/api/api";

const ACCOUNT_TYPES = {
  Editor: "editor",
  "Project Manager": "project_manager",
  Inspector: "inspector",
};

function AccountForm({ mode = "add" }) {
  const navigate = useNavigate();
  const { id } = useParams();

  const [form, setForm] = React.useState({
    fullName: "",
    email: "",
    password: "",
    accountType: "",
    image: null,
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const isEditMode = mode === "edit";

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/sign-in");
    }
  }, [navigate]);

  useEffect(() => {
    if (isEditMode && id) {
      fetchUserData();
    }
  }, [isEditMode, id]);

  const fetchUserData = async () => {
    try {
      const response = await api.getUserDetail({ userId: id });
      if (response.status) {
        const userData = response.data;
        setForm({
          fullName: userData.full_name || "",
          email: userData.email || "",
          accountType: userData.account_type || "",
          image: userData.profile_picture || null,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data");
    }
  };

  const handleChange = (e) =>
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setForm((prev) => ({ ...prev, image: URL.createObjectURL(file) }));
    }
  };


  const validateForm = () => {
    const requiredFields = {
      "Full Name": form.fullName,
      "Account Type": form.accountType,
      "Profile Picture": imageFile || form.image,
      "Email": form.email,
    };

    if (!isEditMode) {
      requiredFields.Password = form.password;
    }

    const emptyField = Object.entries(requiredFields).find(
      ([_, value]) => !value
    );
    if (emptyField) {
      toast.error(`${emptyField[0]} is required`);
      return false;
    }
    return true;
  };


  const handleSubmit = async () => {
    if (!validateForm()) return;

    

    try {
      setIsProcessing(true);

      const accountData = isEditMode
        ? {
            userId: id,
            full_name: form.fullName,
            email: form.email,
            account_type: form.accountType,
            profile_picture: imageFile || form.image,
          }
        : {
            full_name: form.fullName,
            email: form.email,
            account_type: form.accountType,
            profile_picture: imageFile || form.image,
            password: form.password,
            login_type: "email",
            device_type: "web",
          };

      const response = isEditMode
        ? await api.updateUserDetail(accountData)
        : await api.addAccount(accountData);

      toast[response.status ? "success" : "error"](response.message);
      if (response.status && isEditMode) {
        navigate(`/inspector-overview/${id}`);
      }
      else if (response.status && !isEditMode) {
        navigate("/accounts");
      }
    } catch (error) {
      console.error(
        "Failed to " + (isEditMode ? "update" : "create") + " account:",
        error
      );
      toast.error(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (displayValue) => {
    const apiValue = ACCOUNT_TYPES[displayValue];
    setForm((prev) => ({ ...prev, accountType: apiValue }));
    setAnchorEl(null);
  };

  const getDisplayAccountType = () => {
    const entries = Object.entries(ACCOUNT_TYPES);
    const found = entries.find(([_, value]) => value === form.accountType);
    return found ? found[0] : "Select Account Type";
  };

  const uploadImageClick = (e) => {
    e.preventDefault();
    document.getElementById("image-upload").click();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={1} mb={2.5}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}>
              <SoftTypography color="white" variant="h6" fontSize="20px">
                <Link to="/accounts" style={{ color: "white" }}>
                  <SoftTypography
                    component="span"
                    fontWeight="light"
                    variant="h6"
                    fontSize="20px">
                    Accounts &gt;{" "}
                  </SoftTypography>
                </Link>
                {isEditMode ? "Edit Account" : "Add New Account"}
              </SoftTypography>
            </SoftBox>

            <SoftBox sx={{ height: "calc(100vh - 180px)", overflowY: "auto" }}>
              <SoftBox
                px={{ xs: 2, md: 3 }}
                pt={2}
                pb={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <SoftTypography
                  mt={1}
                  mb={1.5}
                  fontWeight="bold"
                  fontSize="30px">
                  {isEditMode ? "EDIT ACCOUNT FORM" : "NEW ACCOUNT FORM"}
                </SoftTypography>
                <SoftTypography
                  fontWeight="regular"
                  fontSize="16px"
                  color="black.light"
                  px={{ xs: 2, md: 14 }}
                  textAlign="center">
                  Fill in the details below to{" "}
                  {isEditMode ? "edit" : "create a new"} account. Ensure all
                  fields are accurate for secure access and seamless system
                  management.
                </SoftTypography>
              </SoftBox>

              <SoftBox
                sx={{ px: { xs: 2, md: 14 }, pb: { xs: 4, md: 8 }, pt: 4 }}>
                <SoftBox
                  backgroundColor="input.main"
                  p={{ xs: 1, md: 2 }}
                  borderRadius={12}>
                  <SoftBox
                    px={{ xs: 2, md: 5 }}
                    py={{ xs: 3, md: 5 }}
                    backgroundColor="input.main"
                    borderRadius={12}
                    border="1px solid #02255B">
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "center",
                        }}>
                        <SoftBox
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="15px"
                          backgroundColor="white.main"
                          sx={{
                            height: { xs: "280px", md: "265px" },
                            width: { xs: "280px", md: "265px" },
                            overflow: "hidden",
                          }}>
                          {form.image ? (
                            <img
                              src={form.image}
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "15px",
                              }}
                            />
                          ) : (
                            <FaUser size={190} color="#C1C1C1" />
                          )}
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            id="image-upload"
                            onChange={handleImageUpload}
                          />
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="center">
                          <label htmlFor="image-upload">
                            <SoftTypography
                              component="span"
                              fontWeight="bold"
                              fontSize="14px"
                              color="info"
                              display="flex"
                              alignItems="center"
                              gap={1}
                              sx={{ mt: 2, cursor: "pointer" }}
                              onClick={uploadImageClick}>
                              <MdFileUpload size="20px" />
                              Choose Image
                            </SoftTypography>
                          </label>
                        </SoftBox>
                      </Grid>

                      <Grid item xs={12} md={7}>
                        <SoftBox mb={2}>
                          <SoftInput
                            value={form.fullName}
                            name="fullName"
                            onChange={handleChange}
                            placeholder="Full Name"
                            icon={{
                              component: <PersonIcon />,
                              direction: "left",
                            }}
                            sx={{
                              padding: "16px 12px 16px 4px",
                              "& .MuiInputBase-input::placeholder": {
                                color: "#212121 !important",
                                fontSize: "16px",
                                opacity: 1,
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "16px",
                              },
                            }}
                          />
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftInput
                            value={form.email}
                            name="email"
                            onChange={handleChange}
                            type="email"
                            placeholder="Email Address"
                            icon={{
                              component: <EmailIcon />,
                              direction: "left",
                            }}
                            sx={{
                              padding: "16px 12px 16px 4px",
                              "& .MuiInputBase-input::placeholder": {
                                color: "#212121 !important",
                                fontSize: "16px",
                                opacity: 1,
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "16px",
                              },
                            }}
                          />
                        </SoftBox>
                        {!isEditMode && (
                          <SoftBox mb={2} sx={{ position: "relative" }}>
                            <SoftInput
                              value={form.password}
                              name="password"
                              onChange={handleChange}
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              icon={{
                                component: <LockIcon />,
                                direction: "left",
                              }}
                              sx={{
                                padding: "16px 40px 16px 4px",
                                "&.Mui-focused": {
                                  padding: "16px 40px 16px 4px",
                                },
                                "& .MuiInputBase-input::placeholder": {
                                  color: "#212121 !important",
                                  fontSize: "16px",
                                  opacity: 1,
                                },
                                "& .MuiInputBase-input": {
                                  fontSize: "16px",
                                },
                              }}
                            />
                            <SoftBox
                              onClick={() => setShowPassword(!showPassword)}
                              sx={{
                                position: "absolute",
                                right: "12px",
                                top: "55%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                                color: "text.main",
                              }}>
                              {showPassword ? (
                                <VscEyeClosed size={20} />
                              ) : (
                                <VscEye size={20} />
                              )}
                            </SoftBox>
                          </SoftBox>
                        )}

                        <SoftBox mb={3} sx={{ position: "relative" }}>
                          <SoftBox
                            onClick={handleClick}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: "14px 12px",
                              border: "0.5px solid #6A707C",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}>
                            <AccountBalanceIcon
                              sx={{ mr: 1, color: "primary.main" }}
                            />
                            <SoftTypography
                              fontSize="16px"
                              sx={{
                                padding: "0px 0px 0px 4px",
                                color: "lightBlack.main",
                              }}>
                              {getDisplayAccountType()}
                            </SoftTypography>
                            <IoIosArrowDown
                              size={20}
                              style={{
                                marginLeft: "auto",
                                color: "primary.main",
                              }}
                            />
                          </SoftBox>

                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => setAnchorEl(null)}
                            PaperProps={{
                              sx: {
                                mt: -0.5,
                                border: "1px solid black",
                                borderBottom: "none",
                                padding: 0,
                                borderRadius: 0,
                                width: anchorEl?.offsetWidth,
                                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
                              },
                            }}>
                            {["Editor", "Project Manager", "Inspector"].map(
                              (option) => (
                                <MenuItem
                                  key={option}
                                  onClick={() => handleMenuItemClick(option)}
                                  sx={{
                                    borderRadius: 0,
                                    padding: "14px 12px",
                                    borderBottom: "1px solid black",
                                    fontSize: "14px",
                                    color: "primary.main",
                                    "&:hover": {
                                      backgroundColor: "none !important",
                                    },
                                  }}>
                                  {option}
                                </MenuItem>
                              )
                            )}
                          </Menu>
                        </SoftBox>

                        <SoftBox display="flex" gap={2}>
                          <SoftButton
                            variant="gradient"
                            color="primary"
                            fullWidth
                            onClick={handleSubmit}
                            disabled={isProcessing}
                            sx={{
                              padding: "14px 24px",
                              mt: 1,
                              width: isEditMode ? "50%" : "100%",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}>
                            {isProcessing
                              ? "Processing..."
                              : isEditMode
                              ? "Update"
                              : "Continue"}
                          </SoftButton>

                          {isEditMode && (
                            <SoftTypography
                              onClick={() =>
                                navigate(`/accounts/change-password/${id}`)
                              }
                              component="span"
                              fontWeight="bold"
                              fontSize="16px"
                              color="info"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              gap={1}
                              sx={{ cursor: "pointer", width: "50%" }}>
                              Change Password
                            </SoftTypography>
                          )}
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default AccountForm;
