// @mui material components
import Card from "@mui/material/Card";
import { Menu, MenuItem } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table/Apptable";
import SoftPagination from "components/SoftPagination";
import UserRow, { columns } from "store/action/AppData";
import React, { useState } from "react";
import DeleteInspection from "components/SoftDialog/DeleteInspection";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";
// Data
import useStore from "store/hook";
import api from "store/api/api";
import headerBg from "assets/images/curved-images/Header.png";
import CircularLoader from "components/SoftDialog/Loader";
import Addappdata from "components/SoftDialog/Addappdata";
import DeleteAppdata from "components/SoftDialog/DeleteAppdata";

function Appdata() {
  const navigate = useNavigate();
  const { setDialogue, store } = useStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [status, setStatus] = useState();
  const [expandDisciplineId, setExpandDesciPlineId] = useState(null);

  const {
    isOpen = false,
    disciplineId,
    action,
  } = store?.app?.dialogs?.addAppdata || {};

  const {
    success
  } = store?.app?.dialogs?.isSuccess || {};

  React.useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/sign-in");
    } else {
      getDiscipline();
    }
  }, [navigate, success]);
  
  const getDiscipline = async () => {
    try {
      setIsLoading(true);
      const response = await api.getDisciplineData({});

      if (response.status === 1) {
        setRows(
          response.data.map((discipline) =>
            UserRow(discipline, setDialogue, setExpandDesciPlineId, expandDisciplineId)
          )
        );
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteAppdata/>
      <Addappdata/>
      <SoftBox pt={1} mb={2.5}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "sticky",
                top: 0,
                zIndex: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={3}
              py={2}
            >
              <SoftTypography
                color="white"
                variant="h6"
                fontSize="20px"
                fontWeight="light"
                
              >
                DISCIPLINE
              </SoftTypography>

              <SoftBox
                sx={{
                  cursor: "pointer",
                  border: "2px solid white",
                  borderRadius: "6px",
                  padding: "5px 42px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDialogue({
                    type: "addAppdata",
                    isOpen: true,
                    disciplineId: "",
                    is_deleted: true,
                    action:  'discipline',
                    success: false,
                  });
                }}
              >
                <SoftTypography variant="h6" color="white"
                >
                Add Discipline                
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox
              sx={{
                position: "relative",
                "& .MuiTableContainer-root": {
                  maxHeight: rows.length > 0 ? "calc(100vh - 200px)" : "auto",
                  overflow: rows.length > 0 ? "auto" : "visible",
                },
                "& .MuiTableHead-root": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {isLoading ? (
                <SoftBox
                  display="flex"
                  height="calc(100vh - 180px)"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <SoftTypography variant="subtitle1" color="textSecondary">
                    <CircularLoader type="large" />
                  </SoftTypography>
                </SoftBox>
              ) : rows?.length > 0 ? (
                <Table columns={columns} rows={rows} expandDisciplineId={expandDisciplineId}/>
              ) : (
                <SoftBox
                  sx={{
                    height: "calc(100vh - 182px)",
                    overflowY: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    gap={8}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      backgroundColor="shadow.main"
                    >
                      {columns.map(({ name, align, width }, index) => (
                        <SoftBox
                          key={index}
                          width={width}
                          align={align}
                          fontWeight="bold"
                          fontSize="18px"
                          py="14px"
                          px="24px"
                        >
                          {name}
                        </SoftBox>
                      ))}
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SoftTypography
                        fontSize="90px"
                        fontWeight="light"
                        height="110px"
                        color="fade"
                      >
                        0
                      </SoftTypography>
                      <SoftTypography
                        fontSize="40px"
                        fontWeight="medium"
                        color="fade"
                        pb={2}
                      >
                        APP DATA
                      </SoftTypography>
                      <EmptyFolderIcon style={{ opacity: 0.8 }} />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Appdata;
