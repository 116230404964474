import React from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ReactComponent as OnStatusIcon } from "assets/images/small-logos/logo-onStatus.svg";
import { ReactComponent as OffStatusIcon } from "assets/images/small-logos/logo-offStatus.svg";
import convertMillisecondsToDate from "services/dateconverter";

function Author({ image, name, email, is_deleted, user_id, account_type }) {
  return (
    <SoftBox display="flex" alignItems="center" px="14px" pt="8px">
        <SoftBox mr={2}>
          <SoftAvatar
            src={image}
            alt={name}
            size="lg"
            variant="rounded"
          />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" gap={0.8}>
          <SoftTypography
            variant="button"
            fontWeight="medium"
            color="lightBlack"
            fontSize="16px"
            textTransform="none">
            {name}
          </SoftTypography>
          {is_deleted ? (
            <SoftTypography
              sx={{ fontSize: 10 }}
              color="error"
              fontWeight="medium">
              {"Account deleted"}
            </SoftTypography>
          ) : null}
          <SoftTypography
            variant="caption"
            fontSize="14px"
            color="secondary"
            textTransform="none">
            {email}
          </SoftTypography>
        </SoftBox>
    </SoftBox>
  );
}

export const columns = [
  { name: "Name", align: "left" },
  { name: "Account Type", align: "left" },
  { name: "Last Login", align: "center" },
  // { name: "Status", align: "center" },
  { name: "Action", align: "center" },
];

function Rows(data, setDialogue, refetchUsers) {

  const lastLogin = data.last_login === 0 ? "--" : convertMillisecondsToDate(data.last_login);

  return {
    linkPath: `/inspector-overview/${data.id}`,
    noLinkCells: ["Action"],
    Name: (
      <Author
        image={data.profile_picture}
        name={data.fullName}
        email={data.email}
        is_deleted={data.is_deleted}
        user_id={data.id}
        account_type={data.account_type}
      />
    ),
    "Account Type": (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        fontSize="16px">
        {data.account_type === "project_manager"
          ? "Project Manager"
          : data.account_type}
      </SoftTypography>
    ),
    "Last Login": (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        fontSize="16px">
        {lastLogin}
      </SoftTypography>
    ),
    Status: (
      <SoftTypography
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          height: "45px",
          width: "50px",
          margin: "0 auto",
          cursor: "pointer",
        }}
        onClick={() =>
          setDialogue({
            isOpen: true,
            userId: data.id,
            is_active: data.is_active,
            type: "disableUser",
            callback: refetchUsers,
          })
        }>
        {data.is_active ? <OnStatusIcon /> : <OffStatusIcon />}
      </SoftTypography>
    ),
    Action: data.is_deleted ? null : (
      <SoftBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={3}>
        <DeleteIcon
          sx={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            color: "error.main",
          }}
          onClick={() =>
            setDialogue({
              isOpen: true,
              userId: data.id,
              is_deleted: true,
              type: "deleteUser",
              callback: refetchUsers,
            })
          }
        />
        <Link to={`/accounts/edit/${data.id}`}>
          <EditIcon
            sx={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              color: "white.main",
              backgroundColor: "primary.main",
              padding: "2px",
              borderRadius: "50%",
              marginTop: "8px",
            }}
          />
        </Link>
      </SoftBox>
    ),
  };
}

Author.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  user_id: PropTypes.string,
  is_deleted: PropTypes.bool,
};

export default Rows;
