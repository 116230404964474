import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Nolinks from "../../assets/images/project/Nolinks.png";
import Nomanagers from "../../assets/images/project/Nomanagers.png";
import Nocontact from "../../assets/images/project/Nocontact.png";
// @mui material components
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SelectInspector from "components/SoftDialog/SelectInspector";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Icons and Assets
import { FaUser, FaPlus } from "react-icons/fa6";
import headerBg from "assets/images/curved-images/Header.png";
import { toast } from "react-toastify";

// Store
import useStore from "store/hook";
import api from "store/api/api";
import { Link } from "react-router-dom";
import SoftAvatar from "components/SoftAvatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import formatPhoneNumber from "services/formatPhoneNo";
import CircularLoader from "components/SoftDialog/Loader";

function ProjectForm({ mode = "add" }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { store, setDialogue } = useStore();
  const [form, setForm] = React.useState({
    name: "",
    project_number:"",
    location: "",
    internal_notes: "",
    site_contacts: [],
    links: [],
    inspectors: [],
    currentEmail: "",
    currentPhone: "",
    currentCompanyName: "",
    contactName: "",
    currentCountryCode: "+1",
    currentLink: "",
    contactEditIndex: "",
    linkEditIndex: "",
  });
  const isEditMode = mode === "edit";

  const [selectedInspectors, setSelectedInspectors] = useState([]);

  const [isPopupOpen, setPopupOpen] = React.useState(false); // Popup state

  const [isContactPopupOpen, setSiteContactPopupOpen] = React.useState(false); // Popup state

  const [anchorEl, setAnchorEl] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (code,isoCode) => {
    setForm((prev) => ({ ...prev, currentCountryCode: code, isoCode:isoCode }));
    setAnchorEl(null);
  };

  const handlePopupOpen = () => setPopupOpen(true);

  const countryCodes = ["+93", "+355", "+213", "+376", "+244", "+54", "+374", "+61", "+43", "+994",
  "+973", "+880", "+375", "+32", "+55", "+359", "+1", "+86", "+57", "+506",
  "+385", "+53", "+357", "+420", "+45", "+20", "+358", "+33", "+49", "+30",
  "+91", "+62", "+98", "+964", "+353", "+972", "+81", "+962", "+7", "+254",
  "+965", "+856", "+961", "+218", "+60", "+52", "+31", "+64", "+234", "+47"];

  const countryShortForms = [
    "AFG", "ALB", "DZA", "AND", "AGO", "ARG", "ARM", "AUS", "AUT", "AZE",
    "BHR", "BGD", "BLR", "BEL", "BRA", "BGR", "CAN", "CHN", "COL", "CRI",
    "HRV", "CUB", "CYP", "CZE", "DNK", "EGY", "FIN", "FRA", "DEU", "GRC",
    "IND", "IDN", "IRN", "IRQ", "IRL", "ISR", "JPN", "JOR", "RUS", "KEN",
    "KWT", "LAO", "LBN", "LBY", "MYS", "MEX", "NLD", "NZL", "NGA", "NOR"
  ];


  const handleSiteContactPopOpen = () => setSiteContactPopupOpen(true);

  const handlePopupClose = () => {
    setPopupOpen(false);
    setForm((prev) => ({ ...prev, currentLink: "" }));
  };

  const handleContactPopUpClose = () => {
    setSiteContactPopupOpen(false);
    setForm((prev) => ({
      ...prev,
      currentEmail: "",
      currentPhone: "",
      currentCompanyName: "",
      currentCountryCode: "+1",
      contactEditIndex: "",
      contactName: "",
    }));
  };

  const [selectedInspectorDetails, setSelectedInspectorDetails] =
    React.useState([]);

  React.useEffect(() => {
    const selectedInspector =
      store?.app?.dialogs?.selectInspector?.selectedInspectors;

    if (selectedInspector && selectedInspector.length > 0) {
      setForm((prev) => ({
        ...prev,
        inspectors: selectedInspector.map((inspector) => inspector.id),
      }));
      setSelectedInspectorDetails(selectedInspector);
    }
  }, [store?.app?.dialogs?.selectInspector?.selectedInspectors]);

  React.useEffect(() => {
    if (isEditMode && id) {
      getProjectDetails();
    }
  }, [isEditMode, id]);

  const getProjectDetails = async () => {
    const response = await api.getProjectDetail({ project_id: id });
    setForm({
      name: response?.data?.project?.name,
      location: response?.data?.project?.location,
      internal_notes: response?.data?.project?.internal_notes,
      site_contacts: response?.data?.project?.site_contacts,
      links: response?.data?.project?.links,
      inspectors: [],
      currentEmail: "",
      currentPhone: "",
      currentCountryCode: "+1",
      currentLink: "",
      contactName: "",
      contactEditIndex: "",
      project_number: response?.data?.project?.project_number
    });

    if (response.data.project.manager) {
      setSelectedInspectors(response.data.project.manager);
    }
  };

  const handleChange = (e) =>
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleAddLink = () => {
    // console.log(form.currentLink)
    if (form.currentLink && form.currentLink.trim() !== "") {
      const isLinkPresent = form.links.some(
        (link) => link === form.currentLink
      );
      if (!isLinkPresent) {
        // console.log("coming inside here");
        if (form.linkEditIndex !== "") {
          if(form.links.length == 0){
            setForm((prev) => ({
              ...prev,
              links: [form.currentLink],
              currentLink: "",
              linkEditIndex: "",
            }));
          }else{
            setForm((prev) => ({
              ...prev,
              links: prev.links.map((link, index) =>
                index === form.linkEditIndex ? form.currentLink : link
              ),
              currentLink: "",
              linkEditIndex: "",
            }));
          }
          
        } else {
          setForm((prev) => ({
            ...prev,
            links: [...prev.links, form.currentLink],
            currentLink: "",
          }));
        }
      } else {
        // console.log("coming here");
      }

      handlePopupClose();
    } else {
      console.log("Please enter a valid link");
    }
  };

  const handleAddContact = () => {

    if(form?.contactName == ""){
      toast.error("Please Enter name of contact");
    }
    else {

    if (form.contactEditIndex !== "") {
      setForm((prev) => ({
        ...prev,
        site_contacts: prev.site_contacts.map((contact, index) =>
          index === prev.contactEditIndex
            ? {
                ...contact,
                name: prev.contactName,
                email: prev.currentEmail,
                phoneNo: prev.currentPhone,
                country_code: prev.currentCountryCode,
                isoCode: prev.isoCode,
                companyName: prev.currentCompanyName,
              }
            : contact
        ),
      }));

      setForm((prev) => ({
        ...prev,
        currentEmail: "",
        currentPhone: "",
        currentCountryCode: "+1",
        contactName: "",
        currentCompanyName: "",
        contactEditIndex: "",
      }));
    } else {
      const contactToAdd = {
        name: form.contactName || "",
        email: form.currentEmail || "",
        phoneNo: form.currentPhone || "",
        isoCode: form.isoCode || "",
        country_code: form.currentCountryCode || "",
        companyName: form.currentCompanyName || "",
      };

      if (contactToAdd) {
        setForm((prev) => ({
          ...prev,
          site_contacts: [...prev.site_contacts, contactToAdd],
          contactName: "",
          currentEmail: "",
          currentPhone: "",
          currentCountryCode: "+1",
          currentCompanyName: "",
        }));
      } else {
        setForm((prev) => ({
          ...prev,
          contactName: "",
          currentEmail: "",
          currentPhone: "",
          currentCountryCode: "+1",
          currentCompanyName: "",
        }));
      }
    }

    handleContactPopUpClose();
  }
  };

  const handleDeleteLink = (index) => {
    setForm((prev) => ({
      ...prev,
      links: prev.links.filter((_, i) => i !== index),
    }));
  };

  const handleEditLink = (index) => {
    handlePopupOpen();
    const linkToEdit = form.links[index];
    setForm((prev) => ({
      ...prev,
      currentLink: linkToEdit,
      linkEditIndex: index,
    }));
  };

  const handleDeleteContact = (index) => {
    setForm((prev) => ({
      ...prev,
      site_contacts: prev.site_contacts.filter((_, i) => i !== index),
    }));
  };

  const handleEditContact = (indexToEdit) => {
    setForm((prev) => ({
      ...prev,
      contactEditIndex: indexToEdit,
    }));

    handleSiteContactPopOpen();
    const contactToEdit = form?.site_contacts[indexToEdit];

    setForm((prev) => ({
      ...prev,
      currentEmail: contactToEdit?.email || "",
      currentPhone: contactToEdit?.phoneNo || "",
      currentCountryCode: contactToEdit?.country_code || "",
      contactName: contactToEdit?.name || "",
      currentCompanyName: contactToEdit?.companyName || "",
    }));
  };

  const handleOpenInspectorSelect = () => {
    setDialogue({
      type: "selectInspector",
      isOpen: true,
      selectedInspectors: [],
    });
  };

  const handleRemoverInspector = (inspectorId) => {
    setSelectedInspectors((prev) =>
      prev.filter((item) => item._id !== inspectorId)
    );
  };

  function getIdsFromArray(arr) {
    return arr.map((item) => item._id);
  }

  const validateForm = () => {
    const requiredFields = {
      "Project Name": form.name,
      Location: form.location,
      ...(mode === "add" && { Inspector: form.inspector_id }),
    };

    const emptyField = Object.entries(requiredFields).find(
      ([_, value]) => !value
    );
    if (emptyField) {
      toast.error(`${emptyField[0]} is required`);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    // if (!validateForm()) return;
    setLoading(true)

    try {
      const payload = isEditMode
        ? {
            projectId: id,
            name: form.name,
            location: form.location,
            internal_notes: form.internal_notes,
            manager: getIdsFromArray(selectedInspectors),
            site_contacts: form.site_contacts,
            links: form.links,
            project_number: form.project_number
          }
        : {
            name: form.name,
            location: form.location,
            internal_notes: form.internal_notes,
            manager: getIdsFromArray(selectedInspectors),
            site_contacts: form.site_contacts,
            links: form.links,
            project_number: form.project_number
          };

      const response = isEditMode
        ? await api.editProjectDetail(payload)
        : await api.addProject(payload);

      toast[response.status ? "success" : "error"](response.message);
      
       if (response.status) {
         navigate("/projects");
       }
    } catch (error) {
      console.log("error", error);
    }
    finally{
        setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={isPopupOpen}
        onClose={handlePopupClose}
        aria-labelledby="add-link-dialog-title"
        aria-describedby="add-link-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 0,
            pb: 0,
            pt: 0,
            borderRadius: "12px",
            width: "400px",
          },
        }}
      >
        <DialogTitle
          style={{
            background: "#02255B",
            textAlign: "center",
            color: "#fff",
          }}
          id="add-link-dialog-title"
        >
          <SoftTypography
            style={{
              fontSize: "18px",
            }}
            variant="h5"
            fontWeight="bold"
          >
            Project Link
          </SoftTypography>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              padding: "10px",
            }}
          >
            <p
              style={{
                fontSize: "10px",
              }}
            >
              Provide a project link for inspectors to access any necessary
              information
            </p>
          </div>

          <SoftInput
            fullWidth
            variant="outlined"
            placeholder="Project link"
            value={form.currentLink}
            onChange={(e) => {
              let value = e.target.value.trim();
              // If the value is not empty and does not start with http:// or https://, prepend https://
              if (value && !/^https?:\/\//i.test(value)) {
                value = `https://${value}`;
              }
              setForm((prev) => ({ ...prev, currentLink: value }));
            }}
            name="currentLink"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px", // Rounded corners
                backgroundColor: "#F1F4F8 !important", // Set background color
                border: "none", // Remove border
                "& fieldset": {
                  border: "none", // Removes the outline border
                },
              },
              mt: 1,
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", flexDirection: "column", gap: 2 }}
        >
          <SoftButton
            variant="gradient"
            color="info"
            onClick={handleAddLink}
            sx={{
              width: "100px",
              padding: "5px",
              background: "#02255B",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {form?.linkEditIndex === "" ? "Add" : "Update"}
          </SoftButton>
          {/* <SoftTypography
            fontSize="16px"
            fontWeight="bold"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={handlePopupClose}
          >
            Cancel
          </SoftTypography> */}
        </DialogActions>
      </Dialog>

      {/* //dialog second one  */}
      <Dialog
        open={isContactPopupOpen}
        onClose={handleContactPopUpClose}
        aria-labelledby="add-link-dialog-title"
        aria-describedby="add-link-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 0,
            pb: 0,
            pt: 0,
            borderRadius: "12px",
            width: "400px",
          },
        }}
      >
        <DialogTitle
          style={{
            background: "#02255B",
            textAlign: "center",
            color: "#fff",
          }}
          id="add-link-dialog-title"
        >
          <SoftTypography
            style={{
              fontSize: "18px",
            }}
            variant="h5"
            fontWeight="bold"
          >
            Site Contacts
          </SoftTypography>
        </DialogTitle>

        <DialogContent>
          <SoftTypography
            textAlign="center"
            sx={{ fontSize: "12px", py: "9px" }}
          >
            Provide the email address or phone number of the site contact for
            the inspector for any inquiries or information.
          </SoftTypography>

          <SoftBox display="flex" flexDirection="column" gap={3}>
            <SoftInput
              fullWidth
              variant="outlined"
              placeholder="Name"
              value={form?.contactName}
              onChange={handleChange}
              name="contactName"
              color="#02255B !important"
              required
              sx={{
                padding: "12px 12px",
                "& .MuiInputBase-input::placeholder": {
                  color: "#02255B !important",
                  fontSize: "14px",
                  opacity: 1,
                },
              }}
            />

            <SoftBox
              display="flex"
              alignItems="center"
              width="100%"
              borderRadius="10px"
              border="0.5px solid #02255B"
            >
              <SoftBox
                color="white"
                display="flex"
                alignItems="center"
                pl={1}
                sx={{
                  backgroundColor: "#02255B",
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                  width: "80px",
                  height: "50px",
                  fontSize: "12px",
                  fontWeight: "normal",
                  whiteSpace: "nowrap",
                }}
              >
                Company <br></br>Name
              </SoftBox>

              <TextField
                fullWidth
                variant="outlined"
                placeholder=""
                value={form?.currentCompanyName}
                onChange={handleChange}
                name="currentCompanyName"
                InputProps={{
                  sx: {
                    borderRadius: "0px 8px 8px 0px",
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none !important",
                      },
                      "&:hover fieldset": {
                        border: "none !important",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none !important",
                      },
                    },
                    boxShadow: "none",
                    paddingLeft: "12px",
                  },
                }}
                sx={{
                  flex: 1,
                  minWidth: "200px",
                }}
              />

              <SoftBox
                sx={{
                  pr: "6px",
                  fontSize: "12px",
                  color: "#777",
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                  color: "#02255B",
                }}
              >
                Optional
              </SoftBox>
            </SoftBox>

            <SoftBox
              display="flex"
              alignItems="center"
              width="100%"
              borderRadius="10px"
              border="0.5px solid #02255B"
            >
              <SoftBox
                color="white"
                display="flex"
                alignItems="center"
                pl={1}
                sx={{
                  backgroundColor: "#02255B",
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                  width: "80px",
                  height: "50px",
                  fontSize: "12px",
                  fontWeight: "normal",
                  whiteSpace: "nowrap",
                }}
              >
                E-mail
              </SoftBox>

              <TextField
                fullWidth
                variant="outlined"
                placeholder=""
                value={form?.currentEmail}
                onChange={handleChange}
                name="currentEmail"
                InputProps={{
                  sx: {
                    borderRadius: "0px 8px 8px 0px",
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none !important",
                      },
                      "&:hover fieldset": {
                        border: "none !important",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none !important",
                      },
                    },
                    boxShadow: "none",
                    paddingLeft: "12px",
                  },
                }}
                sx={{
                  flex: 1,
                  minWidth: "200px",
                }}
              />

              <SoftBox
                sx={{
                  pr: "6px",
                  fontSize: "12px",
                  color: "#777",
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                  color: "#02255B",
                }}
              >
                Optional
              </SoftBox>
            </SoftBox>

            <SoftBox
              display="flex"
              alignItems="center"
              width="100%"
              borderRadius="10px"
              border="0.5px solid #02255B"
              overflow="hidden"
            >
              {/* Left Section - Label */}
              <SoftBox
                color="white"
                display="flex"
                alignItems="center"
                pl={1}
                sx={{
                  backgroundColor: "#02255B",
                  width: "180px",
                  height: "50px",
                  fontSize: "12px",
                  fontWeight: "normal",
                  whiteSpace: "nowrap",
                }}
              >
                Phone
              </SoftBox>

              {/* Country Code Dropdown */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}
                sx={{
                  minWidth: "58px",
                  backgroundColor: "#FFFFFF",
                  borderLeft: "1px solid #B4C4DA",
                  pl: "8px",
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                <SoftTypography
                  sx={{
                    fontSize: "16px",
                    color: "#02255B",
                    fontWeight: "medium",
                  }}
                >
                  {form.currentCountryCode} 
                </SoftTypography>
                <ExpandMoreIcon sx={{ color: "#02255B" }} />
              </Box>

              {/* Dropdown Menu */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {countryCodes.map((code, index) => (
                  <MenuItem
                    key={code}
                    value={code}
                    sx={{ fontSize: "14px", color: "#02255B" }}
                    onClick={() => handleClose(code, countryShortForms[index])}
                  >
                    {code} {countryShortForms[index]}
                  </MenuItem>
                ))}
              </Menu>

              {/* Phone Number Input */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder=""
                value={form.currentPhone}
                onChange={handleChange}
                name="currentPhone"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#FFFFFF",
                    border: "none !important",
                    padding: "8px 12px",
                    fontSize: "14px",
                    color: "#02255B",
                    "& fieldset": { border: "none" },
                  },
                }}
              />

              {/* Right Section - Optional Text */}
              <SoftBox
                sx={{
                  padding: "0px 7px",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#02255B",
                  whiteSpace: "nowrap",
                }}
              >
                Optional
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </DialogContent>

        <DialogActions
          sx={{ justifyContent: "center", flexDirection: "column", gap: 2 }}
        >
          <SoftButton
            variant="gradient"
            color="info"
            onClick={handleAddContact}
            sx={{
              width: "100px",
              padding: "5px",
              background: "#02255B",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {form?.contactEditIndex === "" ? "Add" : "Update"}
          </SoftButton>
          {/* <SoftTypography
            fontSize="16px"
            fontWeight="bold"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={handleContactPopUpClose}
          >
            Cancel
          </SoftTypography> */}
        </DialogActions>
      </Dialog>

      {/* dialog second one site contacts      */}

      <SoftBox pt={1} mb={2.5}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}
            >
              <SoftTypography color="white" variant="h6" fontSize="20px">
                <Link to="/projects" style={{ color: "white" }}>
                  <SoftTypography
                    component="span"
                    fontWeight="light"
                    variant="h6"
                    fontSize="20px"
                  >
                    Projects &gt;{" "}
                  </SoftTypography>
                </Link>
                {isEditMode ? "Edit Project" : "Add Project"}
              </SoftTypography>
            </SoftBox>
            <SoftBox sx={{ height: "calc(100vh - 180px)", overflowY: "auto" }}>
              <SoftBox
                px={{ xs: 2, md: 3 }}
                py={2}
                pb={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <SoftTypography
                  mt={1}
                  mb={1.5}
                  fontSize="30px"
                  fontWeight="bold"
                >
                  {isEditMode ? "EDIT PROJECT" : "PROJECT FORM"}
                </SoftTypography>
                <SoftTypography
                  fontWeight="regular"
                  fontSize="16px"
                  color="black.light"
                  px={{ xs: 2, md: 16 }}
                  textAlign="center"
                >
                  Fill in the details below to {isEditMode ? "edit" : "add"} a
                  project. Ensure all fields are accurate for secure access and
                  seamless system management.
                </SoftTypography>
              </SoftBox>

              <SoftBox>
                <SoftBox p={{ xs: 1, md: 2 }} borderRadius={12}>
                  <SoftBox
                    px={{ xs: 2, md: 4 }}
                    py={{ xs: 3, md: 4 }}
                    borderRadius={12}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={4}
                    border="1px solid #02255B"
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={6}>
                        <SoftBox mb={2} display="flex" gap={2}>
                          <SoftBox width="100%"> 
                          <SoftTypography
                            fontWeight="bold"
                            fontSize="20px"
                            mb={0.5}
                          >
                            Project Name
                          </SoftTypography>
                          <SoftInput
                            value={form.name}
                            name="name"
                            onChange={handleChange}
                            placeholder="Enter Project Name"
                            
                            sx={{
                              padding: "14px 12px",
                              "& .MuiInputBase-input::placeholder": {
                                color: "#212121 !important",
                                fontSize: "16px",
                                opacity: 1,
                              },
                            }}
                          />
                          </SoftBox>

                          <SoftBox width="100%"> 
                          <SoftTypography
                            fontWeight="bold"
                            fontSize="20px"
                            mb={0.5}
                          >
                            Project Number
                          </SoftTypography>
                          <SoftInput
                            value={form.project_number}
                            name="project_number"
                            onChange={handleChange}
                            placeholder="Enter Project Number"
                            sx={{
                              padding: "14px 12px",
                              "& .MuiInputBase-input::placeholder": {
                                color: "#212121 !important",
                                fontSize: "16px",
                                opacity: 1,
                              },
                            }}
                          />
                          </SoftBox>
                          
                        </SoftBox>

                        {/* 
                         <SoftBox mb={2}>
                          <SoftTypography
                            fontWeight="bold"
                            fontSize="20px"
                            mb={0.5}>
                            Project Manager
                          </SoftTypography>
                          <SoftBox
                            sx={{
                              padding: "5px",
                              width: "100%",
                              height: "140px",
                              backgroundColor: "input.main",
                              borderRadius: "6px",
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}>
                            <SoftBox
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="6px"
                              backgroundColor="white.main"
                              border={
                                form.selectedInspector
                                  ? "none"
                                  : "0.5px solid #02255B"
                              }
                              sx={{
                                height: "130px",
                                width: "130px",
                                overflow: "hidden",
                              }}>
                              {selectedInspectorDetails?.profile_picture ? (
                                <img
                                  src={selectedInspectorDetails.profile_picture}
                                  alt={selectedInspectorDetails.fullName}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <FaUser size={100} color="#C1C1C1" />
                              )}
                            </SoftBox>
                            <SoftBox
                              sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: selectedInspectorDetails
                                  ? "space-between"
                                  : "flex-end",
                                height: "100%",
                              }}>
                              {selectedInspectorDetails && (
                                <SoftBox>
                                  <SoftTypography
                                    fontSize="16px"
                                    fontWeight="regular"
                                    color="primary">
                                    {selectedInspectorDetails.fullName}
                                  </SoftTypography>
                                  <SoftTypography
                                    fontSize="16px"
                                    fontWeight="regular"
                                    color="primary">
                                    {selectedInspectorDetails.email}
                                  </SoftTypography>
                                </SoftBox>
                              )}
                              <SoftButton
                                variant="contained"
                                color="info"
                                sx={{
                                  padding: "8px 20px",
                                  fontSize: "16px",
                                  width: "220px",
                                  alignSelf: "center",
                                  mb: "6px",
                                }}
                                onClick={handleOpenInspectorSelect}>
                                {selectedInspectorDetails
                                  ? "Change Inspector"
                                  : "Choose Inspector"}
                              </SoftButton>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>   */}

                        <SoftBox mb={2}>
                          <SoftBox
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={0.5}
                          >
                            <SoftTypography fontWeight="bold" fontSize="20px">
                              Project Manager
                            </SoftTypography>
                            <SoftButton
                              variant="contained"
                              color="info"
                              sx={{
                                padding: "8px 12px",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                              onClick={handleOpenInspectorSelect}
                            >
                              Add <FaPlus size={14} />
                            </SoftButton>
                          </SoftBox>

                          <SoftBox
                            mt={1}
                            sx={{
                              maxHeight: "130px",
                              height: "130px",
                              overflowY: "auto",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            {selectedInspectors?.length === 0 ? (
                              <SoftBox
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "130px",
                                }}
                              >
                                <SoftAvatar
                                  src={Nomanagers}
                                  alt="Prakash"
                                  size="xxl"
                                  variant="rounded"
                                  sx={{ mr: 2 }}
                                />
                              </SoftBox>
                            ) : (
                              selectedInspectors?.map((inspector, index) => (
                                <SoftBox
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                    transition: "background-color 0.3s",
                                  }}
                                >
                                  <SoftBox
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      transition: "background-color 0.3s",
                                    }}
                                  >
                                    <SoftAvatar
                                      src={
                                        inspector?.profile_picture
                                          ? inspector?.profile_picture
                                          : "https://d2qp0siotla746.cloudfront.net/img/use-cases/profile-picture/template_0.jpg"
                                      }
                                      alt="Prakash"
                                      size="lg"
                                      variant="rounded"
                                      sx={{ mr: 2 }}
                                    />
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      gap={0.4}
                                    >
                                      <SoftTypography
                                        className="inspector-name"
                                        variant="button"
                                        fontWeight="medium"
                                        fontSize="18px"
                                        textTransform="none"
                                        color="primary"
                                      >
                                        {inspector?.full_name}
                                      </SoftTypography>

                                      <SoftBox
                                        display="flex"
                                        gap="5px"
                                        color="primary"
                                      >
                                        <EmailIcon
                                          sx={{
                                            fontSize: "40px", // Increase the font size here
                                            color: "primary.main",
                                          }}
                                        />

                                        <SoftTypography
                                          className="inspector-email"
                                          variant="caption"
                                          fontSize="18px"
                                          color="primary"
                                          sx={{ display: "block" }}
                                        >
                                          {inspector?.email}
                                        </SoftTypography>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftBox>
                                    <DeleteIcon
                                      sx={{
                                        cursor: "pointer",
                                        width: 20,
                                        height: 20,
                                        mr: 1,
                                        color: "error.main",
                                        mt: 5,
                                      }}
                                      onClick={() =>
                                        handleRemoverInspector(inspector?._id)
                                      }
                                    />
                                  </SoftBox>
                                </SoftBox>
                              ))
                            )}
                          </SoftBox>
                        </SoftBox>

                        <SoftBox mb={2}>
                          <SoftBox
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={0.5}
                          >
                            <SoftTypography fontWeight="bold" fontSize="20px">
                              Project Links
                            </SoftTypography>
                            <SoftButton
                              variant="contained"
                              color="info"
                              sx={{
                                padding: "8px 12px",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                              onClick={handlePopupOpen}
                            >
                              Add <FaPlus size={14} />
                            </SoftButton>
                          </SoftBox>
                          {/* {form.links.length > 0 && ( */}
                          <SoftBox
                            mt={3}
                            sx={{
                              maxHeight: "130px",
                              height: "130px",
                              overflowY: "auto",
                            }}
                          >
                            {form?.links?.length === 0 ? (
                              <SoftBox
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "130px",
                                }}
                              >
                                <SoftAvatar
                                  src={Nolinks}
                                  alt="Prakash"
                                  size="xxl"
                                  variant="rounded"
                                  sx={{ mr: 2 }}
                                />
                              </SoftBox>
                            ) : (
                              form?.links?.map((link, index) => (
                                <SoftBox
                                  key={index}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{
                                    backgroundColor: "#F1F4F8", // Background color for the row
                                    borderRadius: "8px", // Rounded corners
                                    padding: "8px 12px", // Add padding
                                    marginBottom: "4px", // Add spacing between rows
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center">
                                    <LinkIcon
                                      sx={{
                                        color: "primary.main",
                                        marginRight: "8px",
                                        width: 16,
                                        height: 16,
                                        transform: "rotate(-40deg)",
                                      }}
                                    />
                                    <SoftTypography
                                      fontSize="14px"
                                      color="primary"
                                    >
                                      {link}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox>
                                    <DeleteIcon
                                      sx={{
                                        cursor: "pointer",
                                        width: 18,
                                        height: 18,
                                        mr: 1,
                                        color: "error.main",
                                      }}
                                      onClick={() => handleDeleteLink(index)}
                                    />
                                    <EditIcon
                                      sx={{
                                        cursor: "pointer",
                                        width: 18,
                                        height: 18,
                                        color: "primary.main",
                                      }}
                                      onClick={() => handleEditLink(index)}
                                    />
                                  </SoftBox>
                                </SoftBox>
                              ))
                            )}
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            fontWeight="bold"
                            fontSize="20px"
                            mb={0.5}
                          >
                            Location
                          </SoftTypography>
                          <SoftInput
                            value={form.location}
                            name="location"
                            onChange={handleChange}
                            placeholder="Enter Location"
                            sx={{
                              padding: "14px 12px",
                              "& .MuiInputBase-input::placeholder": {
                                color: "#212121 !important",
                                fontSize: "16px",
                                opacity: 1,
                              },
                            }}
                          />
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftTypography
                            fontWeight="bold"
                            fontSize="20px"
                            mb={0.5}
                          >
                            Internal Notes
                          </SoftTypography>
                          <SoftInput
                            value={form.internal_notes}
                            name="internal_notes"
                            onChange={handleChange}
                            placeholder="Add Internal Notes"
                            multiline
                            rows={6}
                            sx={{
                              padding: "14px 12px",
                              "& .MuiInputBase-input::placeholder": {
                                color: "#212121 !important",
                                opacity: 1,
                                fontSize: "16px",
                              },
                            }}
                          />
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftBox
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={0.5}
                          >
                            <SoftTypography fontWeight="bold" fontSize="20px">
                              Site Contacts
                            </SoftTypography>
                            <SoftButton
                              variant="contained"
                              color="info"
                              sx={{
                                padding: "8px 12px",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                              onClick={handleSiteContactPopOpen}
                            >
                              Add <FaPlus size={14} />
                            </SoftButton>
                          </SoftBox>

                          <SoftBox
                            mt={3}
                            sx={{
                              maxHeight: "130px",
                              height: "130px",
                              overflowY: "auto",
                            }}
                          >
                            {form?.site_contacts?.length === 0 ? (
                              <SoftBox
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "130px",
                                }}
                              >
                                <SoftAvatar
                                  src={Nocontact}
                                  alt="Prakash"
                                  size="xxl"
                                  variant="rounded"
                                  sx={{ mr: 2 }}
                                />
                              </SoftBox>
                            ) : (
                              form?.site_contacts?.map((contact, index) => (
                                <SoftBox
                                  key={index}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{
                                    backgroundColor: "#F1F4F8",
                                    borderRadius: "8px",
                                    padding: "8px 12px",
                                    marginBottom: "4px",
                                    overflowX: "auto", 
                            overflowY: "hidden",    
                            whiteSpace: "nowrap",
                            scrollbarWidth: "none",
                            "&::-webkit-scrollbar": { display: "none" },
                                  }}
                                >
                                  <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    gap="2px"
                                    color="primary"
                                  >
                                    <SoftBox
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="start"
                                      gap="8px"
                                    >
                                      <SoftTypography
                                        fontSize="14px"
                                        color="primary"
                                        textTransform="uppercase"
                                        fontWeight="medium"
                                      >
                                        {contact?.name}
                                      </SoftTypography>

                                      {contact?.companyName && (
                                        <SoftTypography
                                          fontSize="12px"
                                          color="primary"
                                          textTransform="capitalize"
                                          fontWeight="medium"
                                        >
                                          {contact?.companyName}
                                        </SoftTypography>
                                      )}
                                    </SoftBox>

                                    <SoftBox display="flex" gap="8px">
                                      {contact?.email && (
                                        <SoftBox
                                          display="flex"
                                          gap="8px"
                                          color="primary"
                                        >
                                          <EmailIcon color="primary" />
                                          <SoftTypography
                                            fontSize="14px"
                                            color="primary"
                                          >
                                            {contact?.email}
                                          </SoftTypography>
                                        </SoftBox>
                                      )}

                                      {contact?.phoneNo && (
                                        <SoftBox
                                          display="flex"
                                          gap="8px"
                                          color="primary"
                                        >
                                          <PhoneIcon color="primary" />
                                          <SoftTypography
                                            fontSize="14px"
                                            color="primary"
                                          >
                                            {contact?.country_code}{" "}
                                            {formatPhoneNumber(
                                              contact?.phoneNo
                                            )}
                                          </SoftTypography>
                                        </SoftBox>
                                      )}
                                    </SoftBox>
                                  </SoftBox>

                                  <SoftBox>
                                    <DeleteIcon
                                      sx={{
                                        cursor: "pointer",
                                        width: 16,
                                        height: 16,
                                        mr: 1,
                                        color: "error.main",
                                      }}
                                      onClick={() => handleDeleteContact(index)}
                                    />
                                    <EditIcon
                                      sx={{
                                        cursor: "pointer",
                                        width: 16,
                                        height: 16,
                                        color: "primary.main",
                                      }}
                                      onClick={() => handleEditContact(index)}
                                    />
                                  </SoftBox>
                                </SoftBox>
                              ))
                            )}
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                    </Grid>
                    <SoftButton
                      variant="gradient"
                      color="primary"
                      onClick={handleSubmit}
                      sx={{
                        padding: "14px 24px",
                        mt: 1,
                        fontSize: "16px",
                        width: "300px",
                        fontWeight: "bold",
                      }}
                    >
                      {loading == true ? <CircularLoader type="small" /> : isEditMode ? "Update" : "Create"}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <SelectInspector
        selectedInspectors={selectedInspectors}
        setSelectedInspectors={setSelectedInspectors}
      />
    </DashboardLayout>
  );
}

export default ProjectForm;



